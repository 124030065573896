import React, { useEffect } from 'react';
import { Outlet, useLocation, useMatch } from 'react-router';
import { Capacitor } from '@capacitor/core';

// Utils
import { DEBT_GOAL } from '../../../components/Optimizer/types';
import { usePageChange } from '../../../hooks/usePageChange';
import {
  useOnboardingContext,
  useOnboardingDispatch,
} from '../../../providers/onboarding/onboarding.provider';
import {
  setSource,
  setGoal,
  setLoanInterestStatus,
} from '../../../providers/onboarding/onboarding.actions';
import AppUrlListener from '../../../utils/deeplink.utils';
import { getCampaignFromParams } from '../../../utils';
import getStorage from '../../../utils/storage.utils';

export const WrapperLayout = () => {
  const storage = getStorage();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  usePageChange();

  const source = params.get('utm_content');
  const isCio = params.get('cio');
  const maximizeHome = params.get('maximizeHome') || params.get('homeMaximize');
  const match = useMatch('/cash');
  const campaign:
    | 'credit-score'
    | 'consolidation'
    | 'save'
    | 'ham'
    | 'cash'
    | string = match ? 'cash' : getCampaignFromParams(params) || '';

  const medium = params.get('utm_medium');

  const state = useOnboardingContext();
  const dispatch = useOnboardingDispatch();

  useEffect(() => {
    const setStorage = async () => {
      const activeSource = await storage.getItem('solve_source');
      if (activeSource && state.source !== activeSource) {
        dispatch(setSource(activeSource));
      }

      if (source) {
        await storage.setItem('solve_source', source);
        await storage.setItem('utm_content', source);
      } else if (isCio) {
        await storage.setItem('utm_content', 'customer.io');
      }

      if (medium) {
        await storage.setItem('utm_medium', medium);
      }

      const hasReferrer = await storage.getItem('referrer');
      if (!hasReferrer && document.referrer) {
        await storage.setItem('referrer', document.referrer);
      }
    };

    setStorage();
  }, [source, medium, document.referrer]);

  useEffect(() => {
    const setStorage = async () => {
      if (maximizeHome === 'true') {
        dispatch(setGoal({ goal: DEBT_GOAL.MAX_HOME }));
        await storage.setItem('solve_goal', DEBT_GOAL.MAX_HOME);
      }
    };

    setStorage();
  }, [maximizeHome]);

  useEffect(() => {
    const setStorage = async () => {
      if (campaign === 'cash') {
        dispatch(setGoal({ goal: DEBT_GOAL.GET_CASH }));
        dispatch(setLoanInterestStatus({ loanInterestStatus: true }));
        await storage.setItem('solve_goal', DEBT_GOAL.GET_CASH);
      }
    };

    setStorage();
  }, [campaign]);

  return (
    <>
      {Capacitor.isNativePlatform() && <AppUrlListener />}
      <Outlet />
    </>
  );
};
