import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';

// Material UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Services
import {
  AUTH_ERROR_CODES,
  useAuthContext,
} from '../../providers/auth.provider';
import { useUserContext } from '../../providers/user/user.provider';

// Utils
import PageData from './SignIn.yaml';
import { handleTap, validateEmail } from '../../utils';
import * as ids from './testids';
import { ROUTE_PATHS } from '../_pages/_utils/router.utils';
import useSnackBar from '../Snackbar/useSnackBar';
import { getAuthenticationErrorMsg } from '../../utils/user.utils';

// Components
import { ModalBody } from '../Onboarding/Onboarding';
import { ButtonContainer } from '../_core/ui/layout.components';
import ButtonStyled from '../Button';
import Button from '../Button';
import { RHFTextInput } from '../inputs/text-input';
import { RHFPasswordInput } from '../inputs/password-input';
import {
  GoogleButton,
  EmailButton,
  PhoneButton,
  AppleButton,
} from '../AuthButton';
import { PhoneField } from '../_core/forms/phone-input.component';
import { Modal } from '../_core/ui/modal.component';

type PageType = {
  title: string;
};

export interface ISignIn {
  additionalTitle?: string;
  className?: string;
}

const SIGN_IN_FORM = {
  EMAIL_PASSWORD: 'EMAIL_PASSWORD',
  PHONE: 'PHONE',
};

const SignIn: React.FC<ISignIn> = ({ additionalTitle, className }) => {
  const navigate = useNavigate();
  const { addMessage } = useSnackBar();

  const {
    handleSignIn,
    handleGoogleAuthSignIn,
    handleAppleAuthSignIn,
    error,
    setError,
    requestOTP,
    setRequestOTP,
    handleVerifyPhoneNumber,
    handleConfirmPhoneNumber,
    handelCheckAuthMethod,
    initialized: authInitialized,
    loading: authLoading,
    firebaseUser,
    destroyRecaptcha,
  } = useAuthContext();

  const {
    loading: userLoading,
    initialized: userInitialized,
    user,
  } = useUserContext();

  const [signInForm, setSignInForm] = React.useState(SIGN_IN_FORM.PHONE);
  const [hasSubmittedPhone, setHasSubmittedPhone] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [attemptLeft, setAttemptLeft] = useState(2);

  const { title }: PageType = PageData;
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, submitCount },
    getValues,
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });

  const [showAltPrompt, setShowAltPrompt] = React.useState(false);
  const [isPhoneTouched, setIsPhoneTouched] = React.useState(false);
  const setPhoneInputTouched = () => {
    setIsPhoneTouched(true);
  };

  const emailError = 'Please enter a valid email address.';
  const passwordError = 'Password must be at least 8 characters long.';
  const watchedEmail = watch('email');
  const watchedPassword = watch('password');
  const watchedOTP = watch('otp');

  const errEccountExists =
    error === AUTH_ERROR_CODES.GOOGLE_FAIL_EMAIL_PASSWORD ||
    error === AUTH_ERROR_CODES.EMAIL_PASSWORD_FAIL_GOOGLE;

  React.useEffect(() => {
    setDisabled(false);
    destroyRecaptcha();
  }, [signInForm]);

  React.useEffect(() => {
    switch (error) {
      case AUTH_ERROR_CODES.PHONE_VERIFICATION_FAILED:
        addMessage('Phone verification failed. Please try again.', false, 5000);
        destroyRecaptcha();
        setDisabled(false);
        setSubmitting(false);
        setError(null);
        break;
    }
  }, [error]);

  const onClick = async ({
    email,
    phone,
    password,
  }: {
    email?: string;
    phone?: string;
    password?: string;
  }) => {
    try {
      switch (signInForm) {
        case SIGN_IN_FORM.PHONE:
          if (phone?.length) {
            setDisabled(true);
            setRequestOTP(true);
            await handleVerifyPhoneNumber(phone, hasSubmittedPhone);
            setHasSubmittedPhone(true);
          }
          break;
        case SIGN_IN_FORM.EMAIL_PASSWORD:
          if (email?.length) {
            setSubmitting(true);
            try {
              const checkAuth = await handelCheckAuthMethod('email', email);
              if (!!checkAuth && !!checkAuth.found) {
                if (checkAuth.authMethod === 'phone') {
                  addMessage(
                    `This email address is already associated with a phone number. Please sign in with your phone number.`,
                    false
                  );
                  setSubmitting(false);
                  setRequestOTP(false);
                  setSignInForm(SIGN_IN_FORM.PHONE);
                  break;
                } else if (checkAuth.authMethod === 'other') {
                  addMessage(
                    'This email address is already associated with another account. Please sign in with your Google or Apple account',
                    false
                  );
                  setSubmitting(false);
                  setRequestOTP(false);
                  break;
                }
              }
            } catch (error) {
              addMessage(
                'We were unable to find an account with that email address. Please sign up for a new account.',
                false
              );
              setSubmitting(false);
              break;
            }
            await handleSignIn(email, password);
          }
          break;
      }
    } catch (e) {
      const errMsg = getAuthenticationErrorMsg(e);
      setShowAltPrompt(true);

      addMessage(
        errMsg || 'Solve service is unavailable, please try again later',
        false,
        5000
      );

      setSubmitting(false);
      setDisabled(false);
    }
  };

  const handlePhoneSubmit = async () => {
    const otp = getValues('otp');
    setSubmitting(true);
    setDisabled(false);
    try {
      const phone = getValues('phone');
      const checkAuth = await handelCheckAuthMethod('phone', phone);
      if (!!checkAuth && !!checkAuth.found) {
        if (checkAuth.authMethod === 'email') {
          addMessage(
            `This phone number is already associated with an email address. Please sign in with your email and password.`,
            false
          );
          setSubmitting(false);
          setRequestOTP(false);
          setSignInForm(SIGN_IN_FORM.EMAIL_PASSWORD);
          return;
        } else if (checkAuth.authMethod === 'other') {
          addMessage(
            'This phone number is already associated with another account. Please sign in with other methods',
            false
          );
          setSubmitting(false);
          setRequestOTP(false);
          return;
        }
      }
    } catch (error) {
      addMessage(
        'We were unable to find an account with that phone number. Please sign up for a new account.',
        false
      );
      setSubmitting(false);
    }

    if (attemptLeft == 0) {
      addMessage(
        'Maximum attempts reached. Please re-enter your phone number and request a new code.',
        false
      );
      setAttemptLeft(2);
      setDisabled(false);
      setSubmitting(false);
      setRequestOTP(true);
      return;
    }

    try {
      await handleConfirmPhoneNumber(otp, true);
    } catch (error) {
      addMessage(
        `Code incorrect. Please try again. You have ${attemptLeft} attempt(s) left`,
        false
      );
      setAttemptLeft(attemptLeft - 1);
      setRequestOTP(true);
    }
  };

  if (user?.hasBureauAuth) return <Navigate to={ROUTE_PATHS.DASHBOARD} />;
  if (user) return <Navigate to={ROUTE_PATHS.CONSENT} />;
  if (firebaseUser) return <Navigate to={ROUTE_PATHS.ONBOARDING} />;

  return (
    <div className={className}>
      <h1>
        {title} {additionalTitle}
      </h1>
      <form onSubmit={handleSubmit(onClick)}>
        <div>
          {signInForm === SIGN_IN_FORM.PHONE && (
            <>
              <PhoneField
                name="phone"
                control={control}
                setTouched={setPhoneInputTouched}
                touched={isPhoneTouched}
                error={errors.phone}
              />
              <p
                style={{
                  margin: 0,
                  position: 'relative',
                  top: '-16px',
                  fontSize: '14px',
                }}
              >
                We’ll text you to confirm your number. Standard message and data
                rates apply
              </p>
            </>
          )}
          {showAltPrompt && (
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'normal',
                marginBottom: '32px',
              }}
            >
              We were unable to find an account with that email. If you signed
              up with your phone number, try logging in with your phone instead.
            </p>
          )}
          {signInForm === SIGN_IN_FORM.EMAIL_PASSWORD && (
            <RHFTextInput
              autoComplete="email"
              autoFocus
              testid={ids.emailField}
              name="email"
              type="email"
              label="Email"
              // @ts-ignore
              register={register}
              options={{ required: true, validate: validateEmail }}
              error={errors?.email ? emailError : undefined}
              value={watchedEmail}
            />
          )}
          {signInForm === SIGN_IN_FORM.EMAIL_PASSWORD && (
            <RHFPasswordInput
              autoComplete="password"
              testid={ids.password}
              name="password"
              label="Password"
              // @ts-ignore
              register={register}
              options={{
                required:
                  signInForm === SIGN_IN_FORM.EMAIL_PASSWORD && submitCount > 1,
                minLength: 8,
              }}
              error={errors?.password ? passwordError : undefined}
              value={watchedPassword}
            />
          )}
          <div
            style={{
              display: signInForm === SIGN_IN_FORM.PHONE ? 'inherit' : 'none',
            }}
            id="recaptcha-sign-in"
          ></div>

          <Button
            type="submit"
            variant="contained"
            disabled={disabled || submitting || requestOTP}
          >
            Continue
          </Button>
        </div>
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '18px',
            margin: '4px 0',
          }}
        >
          or
        </span>
        <AppleButton
          onClick={async () => {
            await handleAppleAuthSignIn();
          }}
          isSignIn={true}
        />
        <GoogleButton
          onClick={async () => {
            await handleGoogleAuthSignIn();
          }}
          isSignIn={true}
        />
        {signInForm !== SIGN_IN_FORM.PHONE && (
          <PhoneButton
            onClick={() => {
              setSignInForm(SIGN_IN_FORM.PHONE);
            }}
            isSignIn={true}
          />
        )}
        {signInForm !== SIGN_IN_FORM.EMAIL_PASSWORD && (
          <EmailButton
            onClick={() => setSignInForm(SIGN_IN_FORM.EMAIL_PASSWORD)}
            isSignIn={true}
          />
        )}
        {signInForm === SIGN_IN_FORM.EMAIL_PASSWORD && (
          <Link
            to={ROUTE_PATHS.FORGOT_PASSWORD}
            data-testid={ids.forgotPassword}
          >
            Forgot password?
          </Link>
        )}
      </form>

      <Modal open={errEccountExists} onClose={() => setError(null)}>
        <ModalBody>
          <h3>
            An account exists with that email using
            {error === AUTH_ERROR_CODES.GOOGLE_FAIL_EMAIL_PASSWORD
              ? ' email and password'
              : ''}
            {error === AUTH_ERROR_CODES.EMAIL_PASSWORD_FAIL_GOOGLE
              ? ' Google Sign In'
              : ''}
            . Please sign in using your other credentials
          </h3>

          <ButtonContainer>
            <ButtonStyled variant="contained" onClick={() => setError(null)}>
              Close
            </ButtonStyled>
          </ButtonContainer>
        </ModalBody>
      </Modal>

      <Modal
        open={requestOTP}
        onClose={() => {
          setSubmitting(false);
          setRequestOTP(false);
          setDisabled(false);
        }}
      >
        <ModalBody>
          <h3>Enter the code we sent over SMS to {getValues('phone')}</h3>
          <RHFTextInput
            autoFocus
            name="otp"
            // @ts-ignore
            register={register}
            placeholder="- - - - - -"
            type="number"
            options={{
              required: true,
              minLength: 6,
              maxLength: 6,
            }}
            value={watchedOTP}
            label={''}
          />

          <ButtonContainer>
            <ButtonStyled variant="contained" onClick={handlePhoneSubmit}>
              Submit
            </ButtonStyled>
          </ButtonContainer>
        </ModalBody>
      </Modal>
    </div>
  );
};

/**
 * Styled version of component
 */
const StyledSignIn = styled(SignIn)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 860px;
  width: 400px;
  margin: 32px auto;
  @media ${({ theme }) => theme.mq.desktopMin} {
    margin-top: 100px;
    align-items: center;
  }
  && .MuiAlert-root {
    font-family: Majorant-Regular;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  }

  h1 {
    font-family: Majorant-Medium;
    font-size: 30px;
    line-height: 32px;
    margin: 0 auto;
    margin-bottom: 24px;
    font-weight: 500;
    @media screen and ${({ theme }) => theme.mq.desktopSmallMin} {
      font-size: 40px;
      line-height: 46px;
      margin: 0 auto;
      margin-bottom: 24px;
    }
  }

  a {
    color: ${({ theme }) => theme.gray6};
    text-decoration: none;
  }

  & #recaptcha-sign-in {
    & > div {
      padding-bottom: 42px;
    }
  }
`;

/**
 * Default export is styled version
 */
export default StyledSignIn;
