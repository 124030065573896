import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

// Utils
import { ROUTE_PATHS } from './_utils/router.utils';

// Pages
import { HomeLayout } from './_layouts/home.layout';
import { DashboardLayout } from './_layouts/dashboard.layout';
import Home from '../Index/Home';
import PartnerLanding, { PARTNER } from '../Better/landing';
import SignIn from '../Index/SignIn';
import { WrapperLayout } from './_layouts/wrapper.layout';
import { ErrorLayout } from './_layouts/error.layout';
import NotFoundPage from './not-found.page';
import MobileHome from '../Index/MobileHome';
import { StripeRedirectPage } from './stripe-redirect.page';
import DocusignRedirectPage from './docusign-redirect.page';

export const ROUTES = [
  <Route element={<WrapperLayout />}>
    <Route
      path={ROUTE_PATHS.ACH_TERMS}
      lazy={() => import('./ach-terms.page')}
    />
    <Route
      path={ROUTE_PATHS.LOAN_TERMS}
      lazy={() => import('./loan-terms.page')}
    />
    <Route path="/stripe-redirect" element={<StripeRedirectPage />} />
    <Route path={ROUTE_PATHS.HOME} element={<HomeLayout />}>
      <Route
        index
        element={Capacitor.isNativePlatform() ? <MobileHome /> : <Home />}
      />
      <Route
        path={ROUTE_PATHS.FIX_MY_CREDIT}
        lazy={() => import('./_landing/fix-my-credit.page')}
      />
      <Route
        path={ROUTE_PATHS.PRICING}
        lazy={() => import('../Pricing/Pricing.page')}
      />
      <Route
        path={ROUTE_PATHS.BETTER}
        element={<PartnerLanding partner={PARTNER.better} />}
      />
      <Route path={ROUTE_PATHS.CASH} element={<Home />} />
      <Route
        path={ROUTE_PATHS.AUTHENTICATE}
        lazy={() => import('../Index/Authenticate')}
      />
      <Route
        path={ROUTE_PATHS.FORGOT_PASSWORD}
        lazy={() => import('../Index/ForgotPassword')}
      />
      <Route
        path={ROUTE_PATHS.RESET_PASSWORD}
        lazy={() => import('../Index/ResetPassword')}
      />
      <Route
        path={ROUTE_PATHS.MAGIC_LINK_SENT}
        lazy={() => import('../Index/MagicLinkSent')}
      />
      <Route path={ROUTE_PATHS.LOGIN} element={<SignIn />} />
    </Route>
    {/* ,
    <Route path={ROUTE_PATHS.LO_TOOL} element={<CustomerServiceLayout />}>
      <Route
        index
        lazy={() =>
          import('../MortgageQualification/MortgageQualificationTool')
        }
      />
    </Route> */}
    ,
    <Route
      path={ROUTE_PATHS.ONBOARDING}
      lazy={() => import('./_layouts/onboarding.layout')}
    >
      <Route index lazy={() => import('../Onboarding/GoalInfo')} />
      <Route
        path={ROUTE_PATHS.TENANCY}
        lazy={() => import('../Onboarding/RentInfo')}
      />
      <Route
        path={ROUTE_PATHS.ADDRESS}
        lazy={() => import('../Onboarding/LivingInfo')}
      />
      <Route
        path={ROUTE_PATHS.INCOME}
        lazy={() => import('../Onboarding/IncomeInfo')}
      />
      <Route
        path={ROUTE_PATHS.LOAN_INTEREST}
        lazy={() => import('../Onboarding/LoanInterest')}
      />
      <Route
        path={ROUTE_PATHS.REGISTER}
        lazy={() => import('../Index/CreateAccount')}
      />
      <Route
        path={ROUTE_PATHS.SUBSCRIPTION}
        lazy={() => import('../Onboarding/Subscription/Subscription.container')}
      />
      <Route
        path={ROUTE_PATHS.CONSENT}
        lazy={() => import('../Onboarding/ConsentInfo')}
      />
      <Route
        path={ROUTE_PATHS.BUREAU_AUTH}
        lazy={() =>
          import('./BureauAuthentication/bureau-authentication.container')
        }
      />
      <Route
        path={ROUTE_PATHS.REVIEW_INFO}
        lazy={() => import('../Onboarding/ReviewInfo')}
      />
      <Route
        path={ROUTE_PATHS.EDIT_INFO}
        lazy={() => import('../Onboarding/Verification')}
      />
      <Route
        path={ROUTE_PATHS.FULL_SSN}
        lazy={() => import('../Onboarding/FullSSN')}
      />
      <Route
        path={ROUTE_PATHS.BUREAU_NOT_FOUND}
        lazy={() => import('./bureau-not-found.page')}
      />
    </Route>
    ,
    <Route path={ROUTE_PATHS.DASHBOARD} element={<DashboardLayout />}>
      <Route
        path=""
        lazy={() => import('../Optimizer/Dashboard/DashboardProvider')}
      />
      <Route
        path={ROUTE_PATHS.LOAN_APPLICATION}
        lazy={() => import('./loan-application.page')}
      />
      <Route
        path={ROUTE_PATHS.ACCOUNT}
        lazy={() => import('../Account/Account')}
      />
      <Route
        path={ROUTE_PATHS.UPGRADE}
        lazy={() => import('./UpgradePage/upgrade.page')}
      />
      <Route
        path={ROUTE_PATHS.MY_DEBTS}
        lazy={() => import('../Optimizer/Dashboard/Debt/ManageDebts')}
      />
      <Route
        path={`${ROUTE_PATHS.RECOMMENDATION}/:index`}
        lazy={() => import('../Optimizer/Recommendation')}
      />
      <Route
        path={ROUTE_PATHS.CREDIT_SCORE}
        lazy={() => import('../CreditScore/CreditScore.page')}
      />
    </Route>
    ,
    <Route element={<ErrorLayout />}>
      <Route path="*" element={<NotFoundPage />} />
      <Route
        path={ROUTE_PATHS.ONBOARDING_ERROR}
        lazy={() => import('./onboarding-error.page')}
      />
      <Route
        path={ROUTE_PATHS.UNHANDLED_ERROR}
        lazy={() => import('./unhandled-error.page')}
      />
      <Route
        path={ROUTE_PATHS.SERVICE_UNAVAILABLE}
        lazy={() => import('./service-unavailable.page')}
      />
      <Route
        path={ROUTE_PATHS.LOCKED_OUT}
        lazy={() => import('./locked-out.page')}
      />
      <Route
        path={ROUTE_PATHS.DOCUSIGN_REDIRECT}
        element={<DocusignRedirectPage />}
      />
    </Route>
  </Route>,
];

export const createRouter = () => {
  return createBrowserRouter(createRoutesFromElements(ROUTES));
};
