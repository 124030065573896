export default window.Sentry;

export const captureException = (e: Error, context?: any) => {
  if (process.env.USE_EMULATOR) {
    console.error(e);
  }

  window.Sentry?.captureException(e, {
    contexts: { errorInfo: context || {} },
  });
};

export const captureMessage = (msg: string, context?: any) => {
  if (process.env.USE_EMULATOR) {
    console.log(msg);
  }

  window.Sentry?.captureMessage(msg, {
    contexts: { state: context || {} },
    user: { id: context?.userId },
  });
};

export const captureWarning = (msg: string, context?: any) => {
  if (process.env.USE_EMULATOR) {
    console.warn(msg);
  }

  window.Sentry?.captureMessage(msg, {
    level: 'warning',
    contexts: { state: context || {} },
    user: { id: context?.userId },
  });
};
