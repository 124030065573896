import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { Column, DesktopRow } from '../page-components-copy/components';
import { useWindowWidth } from '../../hooks/useWindowSize';
import { IMAGES, getImage } from '../../utils';

const GreyWindow = getImage(IMAGES.GREY_WINDOW.ref);
const BlueWindow = getImage(IMAGES.BLUE_WINDOW.ref);
const YellowWindow = getImage(IMAGES.YELLOW_WINDOW.ref);
const Pointer = getImage(IMAGES.POINTER.ref);

const mouseMove = keyframes`
0% {
    top: 50%;
    left: 47%;
}
/* move up and right */
25% {
    top: 43%;
    left: 49%;
}
/* move left and down */
75% {
    top: 60%;
    left: 49%;
}
/* move right and up */
100% {
    top: 50%;
    left: 47%;
}
`;

const greyWindowMove = keyframes`
0% {
    top: 0%;
    left: 35%;
}
/* move down and left */
25% {
    top: 5%;
    left: 32%;
}
/* move right and up */
75% {
    top: -5%;
    left: 37%;
}
/* move left and up */
100% {
    top: 0%;
    left: 35%;
}
`;

const blueWindowMove = keyframes`
0% {
    top: 13%;
    left: 42%;
}
25% {
    top: 10%;
    left: 44%;
}
75% {
    top: 15%;
    left: 43%;
}
100% {
    top: 13%;
    left: 42%;
}
`;

const yellowWindowMove = keyframes`
0% {
    top: 40%;
    left: 25%;
}
25% {
    top: 43%;
    left: 25%;
}
75% {
    top: 37%;
    left: 22%;
}
100% {
    top: 40%;
    left: 25%;
}
`;

const Container = styled('div')`
  width: 100%;
  height: 720px;
  margin: 40px auto 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  padding-top: calc(env(safe-area-inset-top, -40px) + 40px);

  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    height: 500px;
    max-width: 1200px;
    margin: 132px auto 0 auto;
    padding: 0;
  }
`;

const LenderName = styled('span')`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #02101b;
  z-index: -1;
`;

const LenderContainer = styled(Column)`
  height: 170px;
  overflow: hidden;
  background: linear-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 1)
  );
  text-align: center;
  margin-bottom: 50px;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    margin-bottom: 0;
    text-align: left;
  }
`;
const EmptyColumn = styled('div')`
  display: none;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    display: flex;
    flex: 1 !important;
    &:first-child {
      flex: 0.5 !important;
    }
  }
`;

const Title = styled('h2')`
  width: 100%;
  margin: 0 auto 60px auto;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  height: 108px;
  justify-content: center;
  text-align: center;

  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    display: flex;
    justify-content: center;
    margin: 0 auto 110px auto;
    font-size: 40px;
    line-height: 46px;
    height: auto;
  }

  & div {
    display: none;
    @media ${({ theme }) => theme.mq.desktopLargeMin} {
      display: inline;
      position: relative;
    }
  }
`;

const ExtraTime = styled('h3')`
  width: 100%;
  margin: -42px auto 12px auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    display: flex;
    justify-content: center;
    margin: -80px auto 12px auto;
    font-size: 24px;
    line-height: 26px;
  }
`;

const expandWidth = keyframes`
0% {
    width: 7px;
}
32% {
    width: 7px;
}
33% {
    width: 14px;
}
65% {
    width: 14px;
}
66% {
    width: 21px;
}
100% {
    width: 21px;
}
`;

const Dots = styled('span')`
  position: absolute;
  display: block;
  overflow: hidden;
  animation: 2s ${expandWidth} infinite;
`;

const Content = styled(DesktopRow)`
  justify-content: space-around;
  & > div {
    @media ${({ theme }) => theme.mq.desktopLargeMin} {
      flex: 1.5;
    }
  }
`;

const WindowContainer = styled('div')`
  position: relative;
  width: 100%;
  margin-left: -25%;
  height: 300px;

  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    margin-left: 0;
  }
  z-index: 1;

  & img {
    position: absolute;
  }
  & img:nth-child(1) {
    z-index: 3;
    top: 50%;
    left: 47%;
    animation: 6s ${mouseMove} ease-out infinite alternate;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
  & img:nth-child(2) {
    z-index: 2;
    top: 0%;
    left: 35%;
    animation: 6s ${greyWindowMove} ease-out infinite alternate;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
  & img:nth-child(3) {
    z-index: 1;
    top: 13%;
    left: 42%;
    animation: 6s ${blueWindowMove} ease-out infinite alternate;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
  & img:nth-child(4) {
    z-index: 0;
    top: 40%;
    left: 25%;
    animation: 6s ${yellowWindowMove} ease-out infinite alternate;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
  }
`;

const titles = [
  'Pulling your credit report.',
  'Pulling your credit report..',
  'Pulling your credit report...',
  'Analyzing your credit report.',
  'Analyzing your credit report..',
  'Analyzing your credit report...',
];

const mobileFinalTitles = [
  'Finding the best options for you.',
  'Finding the best options for you..',
  'Finding the best options for you...',
];

const mobileTitles = [
  ...titles,
  ...mobileFinalTitles,
  ...mobileFinalTitles,
  ...mobileFinalTitles,
  ...mobileFinalTitles,
];

export const LoanLoader = ({ extraWait = false }: { extraWait?: boolean }) => {
  const { isMobile } = useWindowWidth();
  const [activeTitle, setActiveTitle] = React.useState(titles[0]);
  const [isLongLoad, setIsLongLoad] = React.useState(false);

  const titleRotator = (timers) => {
    const workingTitles = isMobile ? mobileTitles : titles;

    workingTitles.forEach((title, index) => {
      timers.push(
        setTimeout(() => {
          setActiveTitle(title);
        }, 2000 * index)
      );
    });
  };

  React.useEffect(() => {
    // make sure we clean up timers once the component no longer exists
    const titleTimers = [];
    titleRotator(titleTimers);
    return () => {
      titleTimers.forEach((timer) => {
        clearTimeout(timer);
      });
    };
  }, [isMobile]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLongLoad(true);
    }, 20000); // 20 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container>
      {isLongLoad ? (
        <h2
          style={{
            width: '100%',
            margin: '0 auto 60px auto',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          We're analyzing your credit report but it is taking longer than
          expected. Thanks for your patience!
        </h2>
      ) : (
        <Title>
          {activeTitle}
          <div>
            <Dots>...</Dots>
          </div>
        </Title>
      )}
      {extraWait && (
        <ExtraTime>
          This will take a minute or two - we're working on speeding it up!
        </ExtraTime>
      )}
      <Content>
        <EmptyColumn />
        <WindowContainer>
          <img src={Pointer} />
          <img src={GreyWindow} />
          <img src={BlueWindow} />
          <img src={YellowWindow} />
        </WindowContainer>
        <EmptyColumn />
      </Content>
    </Container>
  );
};
