import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FirebaseFirestore } from '@capacitor-firebase/firestore';

// Services
import { useAuthContext } from '../auth.provider';
import {
  IOnboardingData,
  hasFatalError,
  hydrateOnboardingData,
  setInitialized,
  setLoading,
} from './onboarding.actions';
import { initialState, onboardingReducer } from './onboarding.reducer';
import { useUserContext, useUserDispatch } from '../user/user.provider';
import { setUser, setLoading as setUserLoading } from '../user/user.actions';
import { getActiveOnboardingStep } from './onboarding.utils';
import { captureException } from '../../analytics/sentry';
import {
  createUser,
  getUser,
  updateIdentity,
} from '../../services/user.service';
import { identifyUser } from '../user/user.utils';
import { ROUTE_PATHS } from '../../components/_pages/_utils/router.utils';
import { UnauthorizedError } from '../../services/error-types';
import { Capacitor } from '@capacitor/core';
import { getViewportType } from '../../hooks/useWindowSize';

const OnboardingContext = React.createContext(null);
const OnboardingDispatchContext = React.createContext(null);

export const OnboardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(onboardingReducer, initialState);
  const [isCreating, setCreating] = useState(false);

  const {
    firebaseUser,
    initialized: authInitialized,
    loading: authLoading,
  } = useAuthContext();
  const {
    user,
    loading: userLoading,
    initialized: userInitialized,
  } = useUserContext();
  const userDispatch = useUserDispatch();

  const [hydrating, setHydrating] = useState(false);

  const authReady = !authLoading && authInitialized;
  const userReady = !userLoading && userInitialized;
  const isReady = authReady && userReady && !hydrating && !state.loading;

  useEffect(() => {
    if (isReady) {
      const fetchUserData = async () => {
        try {
          const userDoc = await FirebaseFirestore.getDocument({
            reference: `users/${firebaseUser?.uid}`,
          });
          const nextState = userDoc.snapshot.data as IOnboardingData;

          if (nextState) {
            dispatch(
              hydrateOnboardingData({
                ...nextState,
                primaryPhone: state.primaryPhone || nextState.primaryPhone,
                goal: state.goal || nextState.goal,
                sendEmail: state.sendEmail || nextState.sendEmail,
                sendSMS: state.sendSMS || nextState.sendSMS,
                prequal0: state.prequal0 || nextState.prequal0,
                source: state.source || nextState.source,
                dob: state.dob || nextState.dob,
                livingSituation: {
                  isRenting:
                    state.livingSituation?.homeValue ||
                    nextState.livingSituation?.homeValue
                      ? false
                      : true,
                  homeValue:
                    state.livingSituation?.homeValue ||
                    nextState.livingSituation?.homeValue,
                  rent:
                    state.livingSituation?.rent ||
                    nextState.livingSituation?.rent,
                },
                income: {
                  annual: state.income?.annual || nextState.income?.annual,
                  savings: state.income?.savings || nextState.income?.savings,
                },
                loanInterestStatus:
                  state.loanInterestStatus || nextState.loanInterestStatus,
                personalInfo: {
                  firstName:
                    state.personalInfo?.firstName ||
                    nextState.personalInfo?.firstName,
                  lastName:
                    state.personalInfo?.lastName ||
                    nextState.personalInfo?.lastName,
                  address: {
                    street:
                      state.personalInfo?.address?.street ||
                      nextState.personalInfo?.address?.street,
                    city:
                      state.personalInfo?.address?.city ||
                      nextState.personalInfo?.address?.city,
                    state:
                      state.personalInfo?.address?.state ||
                      nextState.personalInfo?.address?.state,
                    zipCode:
                      state.personalInfo?.address?.zipCode ||
                      nextState.personalInfo?.address?.zipCode,
                  },
                },
                isSubscribed: false,
              })
            );
          }
        } catch (err) {
          console.error('FireStore hydration failed', err);
          if (!err?.message?.includes('Missing or insufficient permissions')) {
            captureException(err);
          }
        }

        // @ts-ignore
        // prettier-ignore
        dispatch(hydrateOnboardingData({
          firebaseRef: firebaseUser?.uid,
        }));

        console.debug('Onboarding Provider - Initialized');

        dispatch(setInitialized(true));
        dispatch(setLoading(false));
        setHydrating(false);
      };

      if (firebaseUser && firebaseUser.uid !== state.firebaseRef) {
        dispatch(setLoading(true));

        setHydrating(true);
        fetchUserData();
      } else if (state.loading || !state.initialized) {
        dispatch(setLoading(false));
        dispatch(setInitialized(true));
      }
    }
  }, [firebaseUser, isReady, state, setHydrating]);

  useEffect(() => {
    if (
      !state.hasFatalError &&
      isReady &&
      state.initialized &&
      !user &&
      firebaseUser &&
      !firebaseUser?.isAnonymous &&
      getActiveOnboardingStep(state) === ROUTE_PATHS.REGISTER &&
      !isCreating
    ) {
      userDispatch(setUserLoading(true));
      const handleCreateUser = async () => {
        setCreating(true);

        try {
          let currUser = await getUser(
            firebaseUser.uid,
            (
              await FirebaseAuthentication.getIdToken()
            ).token
          );

          if (!currUser) {
            await FirebaseAuthentication.getCurrentUser();

            await createUser(
              state,
              (
                await FirebaseAuthentication.getIdToken()
              ).token
            );

            currUser = await getUser(
              firebaseUser.uid,
              (
                await FirebaseAuthentication.getIdToken()
              ).token
            );
          }

          if (currUser) {
            userDispatch(setUser(currUser));

            updateIdentity({
              goal: currUser.goal || state.goal,
              platform: Capacitor.getPlatform(),
              viewport: getViewportType(),
            });

            setCreating(false);
            userDispatch(setUserLoading(false));

            await identifyUser(firebaseUser, currUser);
          }
        } catch (err) {
          captureException(err);
          setCreating(false);
          userDispatch(setUserLoading(false));

          if (err instanceof UnauthorizedError) {
            dispatch(hasFatalError());
          }
        }
      };

      handleCreateUser();
    }
  }, [state, user, firebaseUser?.isAnonymous, isReady, isCreating]);

  console.debug('Onboarding Context', state);

  return (
    <OnboardingContext.Provider value={state}>
      <OnboardingDispatchContext.Provider value={dispatch}>
        {children}
      </OnboardingDispatchContext.Provider>
    </OnboardingContext.Provider>
  );
};

export const useOnboardingDispatch = () => {
  return useContext(OnboardingDispatchContext);
};

export const useOnboardingContext = () => {
  return useContext(OnboardingContext);
};
