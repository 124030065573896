import * as React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useWindowWidth } from '../../hooks/useWindowSize';
import { theme } from '../styles/GlobalStyle';

import { ButtonLink, Row, Column, DesktopRow } from './components';
import { IconListItem } from './icon-list-item';
import { IMAGES, getImage, handleTap } from '../../utils';

const Grid = getImage(IMAGES.GRID.ref);

const Title = styled('h3')`
  font-family: 'Majorant-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  max-width: 462px;
  margin: auto;
  margin-bottom: 24px;
  text-align: center;
  @media ${theme.mq.desktopLargeMin} {
    font-size: 64px;
    line-height: 120%;
    max-width: unset;
  }
`;

const ImageContainer = styled('div')`
  max-width: 400px;
  margin: auto;
  margin-bottom: 12px;
`;

const LeftGrid = styled('img')`
  position: absolute;
  z-index: -2;
  top: -40px;
  left: -33vw;
  width: 100%;
  transform: rotate(180deg);
  @media ${theme.mq.desktopLargeMin} {
    top: -40px;
    left: -33vw;
    width: 100%;
  }
`;

export const ImageAndList = ({
  title,
  image,
  ctas,
  listItems,
  showGrid,
  smallIcons,
  className,
}: {
  title: string;
  image: React.ReactNode;
  ctas: {
    cta: string;
    to?: string;
    callback?: () => void;
    color?: string;
    bgColor?: string;
  }[];
  listItems: {
    icon: React.ReactNode;
    text: string | React.ReactNode;
  }[];
  showBlueArc?: boolean;
  showGrid?: boolean;
  smallIcons?: boolean;
  className?: string;
}) => {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();
  return (
    <DesktopRow
      gap={isMobile ? 40 : 0}
      style={{ position: 'relative' }}
      className={className}
    >
      <div>
        <Title>{title}</Title>
        <ImageContainer>
          {showGrid && isMobile && <LeftGrid src={Grid} />}
          {image}
        </ImageContainer>
        {!isMobile && (
          <Row style={{ justifyContent: 'center', gap: 32 }}>
            {ctas &&
              ctas.map(({ cta, to, callback, color, bgColor }) => (
                <ButtonLink
                  key={cta}
                  onClick={() => (callback ? callback() : navigate(to))}
                  bgColor={bgColor}
                  color={color}
                  style={{
                    minWidth: '277px',
                    margin: 'auto',
                    height: '40px',
                    fontSize: '20px',
                  }}
                >
                  {cta}
                </ButtonLink>
              ))}
          </Row>
        )}
      </div>
      <Column gap={32} style={{ maxWidth: 500, margin: 'auto' }}>
        {listItems.map(({ icon, text }, index) => (
          <IconListItem
            key={`${index}-item`}
            icon={icon}
            text={text}
            smallIcon={smallIcons}
          />
        ))}
      </Column>
      {isMobile && (
        <Column
          style={{
            alignItems: 'center',
            maxWidth: 230,
            margin: 'auto',
            gap: 32,
          }}
        >
          {ctas &&
            ctas.map(({ cta, to, callback, bgColor, color }) => (
              <ButtonLink
                key={to}
                bgColor={bgColor}
                color={color}
                onClick={() => (callback ? callback() : navigate(to))}
              >
                {cta}
              </ButtonLink>
            ))}
        </Column>
      )}
    </DesktopRow>
  );
};
