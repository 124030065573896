import { Preferences } from '@capacitor/preferences';

// Storage interface to match localStorage API
interface Storage {
  getItem(key: string): Promise<string | null>;
  setItem(key: string, value: string): Promise<void>;
  removeItem(key: string): Promise<void>;
}

// SSR-safe storage implementation
class AsyncStorage implements Storage {
  async getItem(key: string): Promise<string | null> {
    return Preferences.get({ key }).then((ret) => ret.value);
  }

  async setItem(key: string, value: string): Promise<void> {
    await Preferences.set({ key, value });

    const result = await Preferences.get({ key });

    console.log('Preferences: ', key, result);
  }

  async removeItem(key: string): Promise<void> {
    await Preferences.remove({ key });
  }
}

const getStorage = (): Storage => {
  return new AsyncStorage();
};

export default getStorage;
