import { FirebaseFirestore } from '@capacitor-firebase/firestore';

import { ROUTE_PATHS } from '../../components/_pages/_utils/router.utils';
import { IOnboardingData } from './onboarding.actions';
import { IUser } from '../../services/user.service';
import { captureException } from '../../analytics/sentry';
import { trackImpression } from '../../analytics/segment';

export const ONBOARDING_STEPS: {
  paths: string[];
  title: string;
  noNav?: boolean;
}[] = [
  { paths: [ROUTE_PATHS.ONBOARDING], title: 'Your goal' },
  { paths: [ROUTE_PATHS.TENANCY], title: 'Your home' },
  { paths: [ROUTE_PATHS.ADDRESS], title: 'Your address' },
  { paths: [ROUTE_PATHS.INCOME], title: 'Household income' },
  { paths: [ROUTE_PATHS.LOAN_INTEREST], title: 'Loan interest' },
  {
    paths: [ROUTE_PATHS.REGISTER, ROUTE_PATHS.SUBSCRIPTION],
    title: 'Create an account',
    noNav: true,
  },
  { paths: [ROUTE_PATHS.SUBSCRIPTION], title: 'Subscription', noNav: true },
  {
    paths: [ROUTE_PATHS.CONSENT, ROUTE_PATHS.BUREAU_AUTH],
    title: 'Credit Report',
  },
  { paths: [ROUTE_PATHS.DASHBOARD], title: 'Results' },
];

export const ONBOARDING_PATH_STEP = {
  [ROUTE_PATHS.ONBOARDING]: 1,
  [ROUTE_PATHS.TENANCY]: 2,
  [ROUTE_PATHS.ADDRESS]: 3,
  [ROUTE_PATHS.INCOME]: 4,
  [ROUTE_PATHS.LOAN_INTEREST]: 5,
  [ROUTE_PATHS.REGISTER]: 6,
  [ROUTE_PATHS.SUBSCRIPTION]: 7,
  [ROUTE_PATHS.CONSENT]: 8,
  [ROUTE_PATHS.BUREAU_AUTH]: 8,
  [ROUTE_PATHS.DASHBOARD]: 9,
};

export const PROGRESS_BAR_NAVIGATION = [4];

export const persistOnboardingData = async (
  firebaseRef: string,
  data: IOnboardingData,
  navigate
) => {
  try {
    await FirebaseFirestore.setDocument({
      reference: `users/${firebaseRef}`,
      data,
    });
  } catch (err) {
    console.error('FireStore failed', err);

    if (!err?.message?.includes('Missing or insufficient permissions')) {
      captureException(err);
    }

    try {
      await FirebaseFirestore.deleteDocument({
        reference: `users/${firebaseRef}`,
      });
    } catch (err) {
      // Fail silently...
    }

    navigate(ROUTE_PATHS.ONBOARDING_ERROR);
  }
};

export const getActiveOnboardingStep = (
  context: IOnboardingData,
  user?: IUser
) => {
  if (!context) {
    return ROUTE_PATHS.ONBOARDING;
  }

  if (!user && !context.goal) {
    return ROUTE_PATHS.ONBOARDING;
  }

  if (!user && !hasValidLivingSituation(context)) {
    return ROUTE_PATHS.TENANCY;
  }

  if (!user && !hasValidAddress(context)) {
    return ROUTE_PATHS.ADDRESS;
  }

  if (!user && !hasValidIncome(context)) {
    return ROUTE_PATHS.INCOME;
  }

  if (!user && !hasLoanInterestStatus(context)) {
    return ROUTE_PATHS.LOAN_INTEREST;
  }

  if (!user) {
    return ROUTE_PATHS.REGISTER;
  }

  if (!hasValidSubscription(user, context)) {
    return ROUTE_PATHS.SUBSCRIPTION;
  }

  if (!hasBureauUser(user)) {
    if (user.subscriptionType === 'PREMIUM') trackImpression('PremiumPaid', {});
    return ROUTE_PATHS.CONSENT;
  }

  if (!hasBureauAuthentication(user)) {
    return ROUTE_PATHS.BUREAU_AUTH;
  }

  return 'COMPLETE';
};

const hasValidLivingSituation = (context: IOnboardingData) => {
  return context.livingSituation?.isRenting
    ? context.livingSituation?.rent !== null
    : context.livingSituation?.homeValue !== null;
};

const hasValidAddress = (context: IOnboardingData) => {
  return (
    context.personalInfo?.address?.street !== null &&
    context.personalInfo?.address?.city !== null &&
    context.personalInfo?.address?.state !== null &&
    context.personalInfo?.address?.zipCode !== null &&
    context.personalInfo?.firstName !== null &&
    context.personalInfo?.lastName !== null
  );
};

const hasValidIncome = (context: IOnboardingData) => {
  // TODO: Handle MAX_HOME (savings)
  return context.income?.annual !== null;
};

const hasLoanInterestStatus = (context: IOnboardingData) => {
  return context.loanInterestStatus !== null;
};

export const hasValidSubscription = (
  user: IUser,
  context?: IOnboardingData
) => {
  return (
    context?.isSubscribed ||
    user?.isSubscribed ||
    user?.email?.includes('@solve.finance')
  );
};

const hasBureauUser = (user: IUser) => {
  return Boolean(user.bureauProviderRef);
};

const hasBureauAuthentication = (user: IUser) => {
  return user.hasBureauAuthentication;
};
