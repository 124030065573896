import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

// Material UI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import Header from '../../Layout/Navigation/Header';
import { useUserContext } from '../../../providers/user/user.provider';
import { useAuthContext } from '../../../providers/auth.provider';
import { ROUTE_PATHS } from '../_utils/router.utils';
import { useRecommendationContext } from '../../../providers/recommendations/recommendation.provider';
import { LenderLoader } from '../../Layout/LenderLoader';
import { LoanLoader } from '../../Layout/LoanLoader';
import { DashboardContextProvider } from '../../../components/Optimizer/Dashboard/dashboard-context';
import { SnackBarProvider } from '../../Snackbar/useSnackBar';
import { Column } from '../../page-components-copy/components';
import { hasValidSubscription } from '../../../providers/onboarding/onboarding.utils';
import styled from 'styled-components';

const IGNORE_LOADER_ROUTES = [
  ROUTE_PATHS.ACCOUNT,
  ROUTE_PATHS.MY_DEBTS,
  ROUTE_PATHS.CREDIT_SCORE,
];

export const DashboardLayout = () => {
  const location = useLocation();

  window.onbeforeunload = null;

  const { firebaseUser, initialized: authInitialized } = useAuthContext();
  const { user, initialized: userInitialized } = useUserContext();
  const { loading, initialized: planInitialized } = useRecommendationContext();

  if (authInitialized && !firebaseUser)
    return <Navigate to={ROUTE_PATHS.AUTHENTICATE} />;

  if (userInitialized && (!user?.hasBureauAuth || !hasValidSubscription(user)))
    return <Navigate to={ROUTE_PATHS.ONBOARDING} />;

  const isUnblocked = IGNORE_LOADER_ROUTES.includes(location.pathname);
  const isLoanApplication = location.pathname.includes(
    ROUTE_PATHS.LOAN_APPLICATION
  );
  const hasOnboarded = user?.hasOnboarded;
  const isPrequalified = user?.application?.isPrequalified;

  if (isLoanApplication && hasOnboarded && !isPrequalified)
    return <Navigate to={ROUTE_PATHS.DASHBOARD} />;

  let isReady = planInitialized && !loading;
  if (isLoanApplication) {
    isReady = hasOnboarded;
  } else if (isUnblocked) {
    isReady = true;
  }

  let content = isReady ? (
    <Outlet />
  ) : isLoanApplication ? (
    <LoanLoader />
  ) : (
    <LenderLoader />
  );

  if (!authInitialized || !userInitialized) {
    content = (
      <Column>
        <Box textAlign="center" my={6}>
          <h2>Getting things ready...</h2>
          <Box my={8} mx="auto" width="32px">
            <CircularProgress size={32} />
          </Box>
        </Box>
      </Column>
    );
  }

  return (
    <Wrapper>
      <Header />
      <SnackBarProvider>
        <DashboardContextProvider>{content}</DashboardContextProvider>
      </SnackBarProvider>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  margin-top: 64px;
`;
