import React from 'react';

// Material UI
import Grid from '@mui/material/Grid';

// Data
import { SUPPORT_EMAIL } from '../../_configuration';

// Components
import { MailLink, Text } from '../_core/typography.components';
import { useAuthContext } from '../../providers/auth.provider';

const DocusignRedirectPage = () => {
  return (
    <>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <Text large align="center">
          <h1>Thank you for authenticating with DocuSign!</h1>
        </Text>
        <Text align="center">
          If you have any questions, contact us by email at{' '}
          <MailLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</MailLink>{' '}
          with any other issues or questions.
        </Text>
      </Grid>
    </>
  );
};

export default DocusignRedirectPage;
