import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Capacitor } from '@capacitor/core';

// Material UI
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { AccordionNav, Title } from './MobileHeaderAccordion';
import { theme } from '../../../styles/GlobalStyle';
import { ZeroStylesButton } from '../../../ZeroStylesButton';
import BetterContact from './BetterContact';
import { ROUTE_PATHS } from '../../../_pages/_utils/router.utils';
import { useAuthContext } from '../../../../providers/auth.provider';
import { IMAGES, getImage } from '../../../../utils';
import getStorage from '../../../../utils/storage.utils';

const logo = getImage(IMAGES.SOLVE_LOGO_BLACK_TEXT.ref);

const menuOptions = [
  {
    title: 'Solutions',
    subtext: '',
    links: [
      {
        to: 'https://www.solve.finance/financial-advisor-for-your-users/',
        title: 'Partners',
        subtext: 'Enable easier borrowing for your users',
      },
      {
        to: 'https://www.solve.finance/financial-advisor-for-individuals/',
        title: 'Individuals',
        subtext: 'Get started with our Debt Optimizer',
      },
    ],
  },
  {
    title: 'Tools',
    subtext: '',
    links: [
      {
        to: 'https://app.solve.finance?utm_content=menu',
        title: 'Debt Optimizer',
        subtext: 'Put better borrowing on automatic with our full service app',
      },
      {
        to: 'https://solve.finance/how-to-borrow-online/',
        title: 'Borrow Online',
        subtext: 'Free: how to borrow online quickly',
      },
      {
        to: 'https://www.solve.finance/how-to-borrow-against-your-house/',
        title: 'Borrow From Your House',
        subtext: 'Free: how do you get equity out of your home?',
      },
      {
        to: 'https://www.solve.finance/independent-mortgage-advice',
        title: 'Home Finance',
        subtext: 'Free: independent mortgage calculators',
      },
      {
        to: 'https://solve.finance/how-much-house-can-i-afford-calculator',
        title: 'Home Affordability Maximizer',
        subtext: 'Free: estimate your home-buying power',
      },
      {
        to: 'https://www.solve.finance/mortgage-dti-calculator',
        title: 'DTI Calculator',
        subtext: 'Free: quickly calculate your debt-to-income ratio',
      },
      {
        to: 'https://www.solve.finance/how-to-improve-your-credit-score',
        title: 'Improve Credit Score',
        subtext: 'Free: how to make your credit score better',
      },
    ],
  },
  {
    title: 'Company',
    subtext: '',
    links: [
      {
        to: 'https://www.solve.finance/about-solve-finance',
        title: 'About',
      },
      {
        to: 'https://apply.workable.com/solve-finance/?lng=en',
        title: 'Careers',
      },
    ],
  },
];

/**
 * Component for the Header of pages
 */
const MobileHeader = ({
  className,
  handleSignOut,
  disableAccountLinks,
}: {
  className?: string;
  handleSignOut: () => Promise<void>;
  disableAccountLinks?: boolean;
}) => {
  const storage = getStorage();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);
  const [source, setSource] = useState<string | null>(
    params.get('utm_source') || params.get('utm_content')
  );

  useEffect(() => {
    const hydrate = async () => {
      const storedSource = await storage.getItem('solve_source');

      if (!storedSource) {
        setSource(storedSource);
      }
    };

    hydrate();
  }, [source]);

  return (
    <>
      <div className={className}>
        <div>
          <Link to="/" style={{ marginTop: '8px' }}>
            <img
              src={logo}
              style={{ width: '99px' }}
              alt="Solve Finance logo"
            />
          </Link>
          <MenuIcon
            style={{ cursor: 'pointer', marginRight: '20px' }}
            data-testid="account-btn"
            onClick={(e) => setIsDrawerOpen(true)}
          />
        </div>
        {(pathname === ROUTE_PATHS.BETTER ||
          (source === 'better' && pathname === '/')) && <BetterContact />}
      </div>

      <SideNavStyled
        isOpen={isDrawerOpen}
        toggleDrawer={() => {
          setIsDrawerOpen(!isDrawerOpen);
        }}
        handleSignOut={handleSignOut}
        disableAccountLinks={disableAccountLinks}
      />
    </>
  );
};

const MobileHeaderStyled = styled(MobileHeader)<{
  currentPage: string;
  disableCloseButton: boolean;
  handleSignOut: () => void;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 2;
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  padding-top: env(safe-area-inset-top);
  top: 0;

  & > div {
    margin-left: 8px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    width: 100%;

    & > a > .MuiSvgIcon-root {
      font-size: 27.43px;
      color: black;

      cursor: pointer;
      ${({ disableCloseButton }) =>
        disableCloseButton &&
        css`
          display: none;
        `}
    }
    & > img {
      max-height: 38px;
    }
  }
  & > .MuiSvgIcon-root {
    color: black;
    font-size: 28px;
    cursor: pointer;
  }
  @media screen and (max-width: 320px) {
    & > div:nth-child(1) {
      margin-left: 5%;
    }
    & > div:nth-child(2) {
      margin-right: 5%;
    }
  }
  & .auth-row {
    & a, a:visited, a:hover, a:active {
      color: ${theme.gray7};
  }
`;

const SingleLink = styled('a')`
  color: ${({ theme }) => theme.white} !important;
  text-decoration: none;
  margin-bottom: 24px;
`;

const SignOutButton = styled(ZeroStylesButton)`
  color: ${({ theme }) => theme.white};
  text-decoration: underline;
  text-align: left;
  padding: 18px 0px;
`;

const SideNav = ({
  className,
  isOpen,
  toggleDrawer,
  handleSignOut,
  disableAccountLinks,
}: {
  className?: string;
  isOpen: boolean;
  toggleDrawer: () => void;
  handleSignOut: () => Promise<void>;
  disableAccountLinks?: boolean;
}) => {
  const { firebaseUser } = useAuthContext();
  const navigate = useNavigate();
  const isNative = Capacitor.isNativePlatform();

  return (
    <Drawer open={isOpen} anchor={'left'} className={className}>
      <div>
        <IconButton
          onClick={() => {
            toggleDrawer();
          }}
          size="large"
        >
          <CloseIcon style={{ margin: 4 }} />
        </IconButton>
      </div>

      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        {!isNative &&
          menuOptions.map(({ title, links }) => (
            <AccordionNav
              title={title}
              toggleDrawer={toggleDrawer}
              links={links}
              key={title}
            />
          ))}
        {!isNative && (
          <SingleLink href="/pricing" onClick={() => toggleDrawer()}>
            <Title>Pricing</Title>
          </SingleLink>
        )}
        {!isNative && (
          <SingleLink
            href="https://www.solve.finance/resources/"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => toggleDrawer()}
          >
            <Title>Resources</Title>
          </SingleLink>
        )}
        {!disableAccountLinks && firebaseUser && (
          <SignOutButton
            onClick={() => {
              toggleDrawer();
              handleSignOut();
            }}
          >
            <Title>Sign out</Title>
          </SignOutButton>
        )}
        {isNative && !firebaseUser && (
          <SignOutButton
            onClick={() => {
              toggleDrawer();
              navigate(ROUTE_PATHS.ONBOARDING);
            }}
          >
            <Title>Sign up</Title>
          </SignOutButton>
        )}
        {!disableAccountLinks && !firebaseUser && (
          <SignOutButton
            onClick={() => {
              toggleDrawer();
              navigate(ROUTE_PATHS.LOGIN);
            }}
          >
            <Title>Sign in</Title>
          </SignOutButton>
        )}
      </ul>
    </Drawer>
  );
};

const SideNavStyled = styled(SideNav)`
  width: 100%;
  min-width: 375px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%);
  transition: none;
  color: white;

  & * {
    transition: none;
  }
  & > .MuiDrawer-paperAnchorLeft {
    width: 100%;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%);
    color: white;
    transition: none !important;
    margin-top: env(safe-area-inset-top, 12px);
    & > div {
      text-align: right;
    }
    & > ul {
      list-style: none;
      padding: 0;
      margin: 20px 0 0 32px;
      & > li {
        margin-bottom: 52px;
        & > a {
          color: white;
          font-family: Majorant-Medium;
          font-size: 2.5em;
          font-style: normal;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }
  & .MuiSvgIcon-root {
    color: white;
  }
  @media screen and (max-width: 360px) {
    width: 290px;
    & > .MuiDrawer-paperAnchorLeft > ul {
      margin-left: 10px;
    }
  }
`;

export default MobileHeaderStyled;
