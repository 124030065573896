import { User } from '@capacitor-firebase/authentication';
import {
  formatDobFromDate,
  formatDobString,
} from './components/inputs/dob-input';
import { IUser } from './services/user.service';
import { DEBT_GOAL } from './components/Optimizer/types';

export enum LOAN_APPLICATION_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
  WITHDRAWN = 'WITHDRAWN',
  SUSPENDED = 'SUSPENDED',
}

export enum LOAN_GROUP {
  AUTO = 'AUTO',
  MORTGAGE = 'MORTGAGE',
  PERSONAL = 'PERSONAL',
  STUDENT = 'STUDENT',
  UNACTIONABLE = 'UNACTIONABLE',
  SECOND_MORTGAGE = 'SECOND_MORTGAGE',
  HEI = 'HEI',
}

export enum DEBT_GROUP {
  AUTO = 'AUTO',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT_BUILDER = 'CREDIT_BUILDER',
  MORTGAGE = 'MORTGAGE',
  PERSONAL = 'PERSONAL',
  LINE_OF_CREDIT = 'LINE_OF_CREDIT',
  SECOND_MORTGAGE = 'SECOND_MORTGAGE',
  STUDENT = 'STUDENT',
  OTHER = 'OTHER',
  IGNORED = 'IGNORED',
  COLLECTIONS = 'COLLECTIONS',
  CHARGEOFFS = 'CHARGEOFFS',
}

export enum PAYMENT_INTERVAL {
  MONTHLY = 'MONTHLY',
  BIWEEKLY = 'BIWEEKLY',
}

export enum OFFER_TYPE {
  PREVIEW = 'PREVIEW',
  FIRM = 'FIRM',
  PREQUALIFIED = 'PREQUALIFIED',
}

export const DEBT_GROUPS = Object.values(DEBT_GROUP);

export const EDITABLE_DEBT_GROUPS = [
  DEBT_GROUP.AUTO,
  DEBT_GROUP.CREDIT_CARD,
  DEBT_GROUP.MORTGAGE,
  DEBT_GROUP.PERSONAL,
  DEBT_GROUP.LINE_OF_CREDIT,
  DEBT_GROUP.STUDENT,
];

export const DEBT_GROUP_DISPLAY_ORDER = [
  DEBT_GROUP.MORTGAGE,
  DEBT_GROUP.SECOND_MORTGAGE,
  DEBT_GROUP.AUTO,
  DEBT_GROUP.CREDIT_CARD,
  DEBT_GROUP.LINE_OF_CREDIT,
  DEBT_GROUP.CREDIT_BUILDER,
  DEBT_GROUP.STUDENT,
  DEBT_GROUP.PERSONAL,
  DEBT_GROUP.OTHER,
  DEBT_GROUP.COLLECTIONS,
  DEBT_GROUP.CHARGEOFFS,
];

export const DEBT_GROUP_LABEL = {
  [DEBT_GROUP.AUTO]: 'Auto Loan',
  [DEBT_GROUP.CREDIT_CARD]: 'Credit Card',
  [DEBT_GROUP.CREDIT_BUILDER]: 'Credit Builder',
  [DEBT_GROUP.MORTGAGE]: 'Mortgage',
  [DEBT_GROUP.PERSONAL]: 'Personal Loan',
  [DEBT_GROUP.LINE_OF_CREDIT]: 'Line of Credit',
  [DEBT_GROUP.SECOND_MORTGAGE]: 'Second Mortgage',
  [DEBT_GROUP.STUDENT]: 'Student Loan',
  [DEBT_GROUP.OTHER]: 'Other',
  [DEBT_GROUP.COLLECTIONS]: 'Collections',
  [DEBT_GROUP.CHARGEOFFS]: 'Chargeoff',
};

export const DEBT_GROUP_LABEL_PLURAL = {
  [DEBT_GROUP.AUTO]: 'Auto Loans',
  [DEBT_GROUP.CREDIT_CARD]: 'Credit Cards',
  [DEBT_GROUP.CREDIT_BUILDER]: 'Credit Builder Loans',
  [DEBT_GROUP.MORTGAGE]: 'Mortgages',
  [DEBT_GROUP.PERSONAL]: 'Personal Loans',
  [DEBT_GROUP.LINE_OF_CREDIT]: 'Lines of Credit',
  [DEBT_GROUP.SECOND_MORTGAGE]: 'Second Mortgages',
  [DEBT_GROUP.STUDENT]: 'Student Loans',
  [DEBT_GROUP.OTHER]: 'Other',
  [DEBT_GROUP.COLLECTIONS]: 'Collections',
  [DEBT_GROUP.CHARGEOFFS]: 'Chargeoffs',
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/*
Takes a number and removes commas from right of decimal
ex : -1,111,111.111,111 becomes
    -1,111,111.111111 because commas are only allowed on the left side.
*/
export const removeCommasAfterDecimal = (value) => {
  if (!value.includes('.')) return value;
  const decimalSplit = value.split('.');
  const leftOfDecimal = decimalSplit[0];
  const rightOfDecimal = decimalSplit[1].replace(/[^0-9.-]/g, '');

  return leftOfDecimal + '.' + rightOfDecimal;
};

export const removeNonNumeric = (x: string): string =>
  x.replace(/[^0-9.-]/g, '');

/**
 * Format number as phone number
 */
export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return phoneNumber;

  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  const match7 = cleaned.match(/^(\d{3})(\d{4})$/);
  if (match7) {
    return `${match7[1]}-${match7[2]}`;
  }
  const match11 = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
  if (match11) {
    return `(${match11[1]}) ${match11[2]}-${match11[3]}`;
  }
  return phoneNumber;
};

/**
 * Pattern match e-mail string
 */
export const validateEmail = (email: string): boolean =>
  /\S+@\S+\.\S+/.test(email);

/**
 * Format a currency value to a string
 */

export const currencyFormat = (num, decimal?: number) => {
  if (!num) return '$0';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal || 0,
  });
  return formatter.format(num);
};

/**
 *  Format term remaining from month to year and month
 */
export const formatTermRemaining = (term: number): string => {
  const years = Math.floor(term / 12);
  const months = term % 12;
  let result = '';
  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''}`;
  }
  if (months > 0) {
    if (result) result += ' and ';
    result += `${months} month${months > 1 ? 's' : ''}`;
  }
  return result;
};

/**
 * Add symbol to input value
 */
export const decorateValueType = (
  type: string,
  value: number | undefined,
  debtGroup?: DEBT_GROUP
): string => {
  if (typeof value === 'undefined' || value === null) {
    return [DEBT_GROUP.CREDIT_CARD, DEBT_GROUP.LINE_OF_CREDIT].includes(
      debtGroup
    )
      ? 'Est. 25%'
      : '';
  }

  if (type === 'currency') {
    return currencyFormat(value);
  }
  if (type === 'number') {
    return `${value}`;
  } else {
    let percent = value.toFixed(3);
    while (
      percent.includes('.') &&
      '.0'.includes(percent[percent.length - 1])
    ) {
      percent = percent.slice(0, percent.length - 1);
    }
    return percent + '%';
  }
};

type LoanTypeType = {
  value: string;
  label: string;
};

/**
 * Types of loans
 */
export const loanTypes: LoanTypeType[] = [
  {
    value: 'Mortgage',
    label: 'Mortgage',
  },
  {
    value: 'StudentLoan',
    label: 'Student Loan',
  },
  {
    value: 'PersonalLoan',
    label: 'Personal Debt',
  },
];

// States available
export const states: string[] = [
  'AK',
  'AL',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const stateValues: { name: string }[] = [
  { name: 'AK' },
  { name: 'AL' },
  { name: 'AZ' },
  { name: 'AR' },
  { name: 'CA' },
  { name: 'CO' },
  { name: 'CT' },
  { name: 'DC' },
  { name: 'DE' },
  { name: 'FL' },
  { name: 'GA' },
  { name: 'HI' },
  { name: 'ID' },
  { name: 'IL' },
  { name: 'IN' },
  { name: 'IA' },
  { name: 'KS' },
  { name: 'KY' },
  { name: 'LA' },
  { name: 'ME' },
  { name: 'MD' },
  { name: 'MA' },
  { name: 'MI' },
  { name: 'MN' },
  { name: 'MS' },
  { name: 'MO' },
  { name: 'MT' },
  { name: 'NE' },
  { name: 'NV' },
  { name: 'NH' },
  { name: 'NJ' },
  { name: 'NM' },
  { name: 'NY' },
  { name: 'NC' },
  { name: 'ND' },
  { name: 'OH' },
  { name: 'OK' },
  { name: 'OR' },
  { name: 'PA' },
  { name: 'PR' },
  { name: 'RI' },
  { name: 'SC' },
  { name: 'SD' },
  { name: 'TN' },
  { name: 'TX' },
  { name: 'UT' },
  { name: 'VT' },
  { name: 'VA' },
  { name: 'WA' },
  { name: 'WV' },
  { name: 'WI' },
  { name: 'WY' },
];

export const getState = (zip: string) => {
  /* Ensure param is a string to prevent unpredictable parsing results */
  if (typeof zip !== 'string') {
    throw new Error('Must pass zipcode as a string');
  }

  /* Ensure we don't parse strings starting with 0 as octal values */
  const zipcode = parseInt(zip.substring(0, 5), 10);

  let stateAbbr;
  let state;

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    stateAbbr = 'AL';
    state = 'Alabama';
  } else if (zipcode >= 99500 && zipcode <= 99999) {
    stateAbbr = 'AK';
    state = 'Alaska';
  } else if (zipcode >= 85000 && zipcode <= 86999) {
    stateAbbr = 'AZ';
    state = 'Arizona';
  } else if (zipcode >= 71600 && zipcode <= 72999) {
    stateAbbr = 'AR';
    state = 'Arkansas';
  } else if (zipcode >= 90000 && zipcode <= 96699) {
    stateAbbr = 'CA';
    state = 'California';
  } else if (zipcode >= 80000 && zipcode <= 81999) {
    stateAbbr = 'CO';
    state = 'Colorado';
  } else if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    stateAbbr = 'CT';
    state = 'Connecticut';
  } else if (zipcode >= 19700 && zipcode <= 19999) {
    stateAbbr = 'DE';
    state = 'Delaware';
  } else if (zipcode >= 32000 && zipcode <= 34999) {
    stateAbbr = 'FL';
    state = 'Florida';
  } else if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    stateAbbr = 'GA';
    state = 'Georgia';
  } else if (zipcode >= 96700 && zipcode <= 96999) {
    stateAbbr = 'HI';
    state = 'Hawaii';
  } else if (zipcode >= 83200 && zipcode <= 83999) {
    stateAbbr = 'ID';
    state = 'Idaho';
  } else if (zipcode >= 60000 && zipcode <= 62999) {
    stateAbbr = 'IL';
    state = 'Illinois';
  } else if (zipcode >= 46000 && zipcode <= 47999) {
    stateAbbr = 'IN';
    state = 'Indiana';
  } else if (zipcode >= 50000 && zipcode <= 52999) {
    stateAbbr = 'IA';
    state = 'Iowa';
  } else if (zipcode >= 66000 && zipcode <= 67999) {
    stateAbbr = 'KS';
    state = 'Kansas';
  } else if (zipcode >= 40000 && zipcode <= 42999) {
    stateAbbr = 'KY';
    state = 'Kentucky';
  } else if (zipcode >= 70000 && zipcode <= 71599) {
    stateAbbr = 'LA';
    state = 'Louisiana';
  } else if (zipcode >= 3900 && zipcode <= 4999) {
    stateAbbr = 'ME';
    state = 'Maine';
  } else if (zipcode >= 20600 && zipcode <= 21999) {
    stateAbbr = 'MD';
    state = 'Maryland';
  } else if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode == 5501 ||
    zipcode == 5544
  ) {
    stateAbbr = 'MA';
    state = 'Massachusetts';
  } else if (zipcode >= 48000 && zipcode <= 49999) {
    stateAbbr = 'MI';
    state = 'Michigan';
  } else if (zipcode >= 55000 && zipcode <= 56899) {
    stateAbbr = 'MN';
    state = 'Minnesota';
  } else if (zipcode >= 38600 && zipcode <= 39999) {
    stateAbbr = 'MS';
    state = 'Mississippi';
  } else if (zipcode >= 63000 && zipcode <= 65999) {
    stateAbbr = 'MO';
    state = 'Missouri';
  } else if (zipcode >= 59000 && zipcode <= 59999) {
    stateAbbr = 'MT';
    state = 'Montana';
  } else if (zipcode >= 27000 && zipcode <= 28999) {
    stateAbbr = 'NC';
    state = 'North Carolina';
  } else if (zipcode >= 58000 && zipcode <= 58999) {
    stateAbbr = 'ND';
    state = 'North Dakota';
  } else if (zipcode >= 68000 && zipcode <= 69999) {
    stateAbbr = 'NE';
    state = 'Nebraska';
  } else if (zipcode >= 88900 && zipcode <= 89999) {
    stateAbbr = 'NV';
    state = 'Nevada';
  } else if (zipcode >= 3000 && zipcode <= 3899) {
    stateAbbr = 'NH';
    state = 'New Hampshire';
  } else if (zipcode >= 7000 && zipcode <= 8999) {
    stateAbbr = 'NJ';
    state = 'New Jersey';
  } else if (zipcode >= 87000 && zipcode <= 88499) {
    stateAbbr = 'NM';
    state = 'New Mexico';
  } else if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode == 6390 ||
    zipcode == 501 ||
    zipcode == 544
  ) {
    stateAbbr = 'NY';
    state = 'New York';
  } else if (zipcode >= 43000 && zipcode <= 45999) {
    stateAbbr = 'OH';
    state = 'Ohio';
  } else if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    stateAbbr = 'OK';
    state = 'Oklahoma';
  } else if (zipcode >= 97000 && zipcode <= 97999) {
    stateAbbr = 'OR';
    state = 'Oregon';
  } else if (zipcode >= 15000 && zipcode <= 19699) {
    stateAbbr = 'PA';
    state = 'Pennsylvania';
  } else if (zipcode >= 300 && zipcode <= 999) {
    stateAbbr = 'PR';
    state = 'Puerto Rico';
  } else if (zipcode >= 2800 && zipcode <= 2999) {
    stateAbbr = 'RI';
    state = 'Rhode Island';
  } else if (zipcode >= 29000 && zipcode <= 29999) {
    stateAbbr = 'SC';
    state = 'South Carolina';
  } else if (zipcode >= 57000 && zipcode <= 57999) {
    stateAbbr = 'SD';
    state = 'South Dakota';
  } else if (zipcode >= 37000 && zipcode <= 38599) {
    stateAbbr = 'TN';
    state = 'Tennessee';
  } else if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    stateAbbr = 'TX';
    state = 'Texas';
  } else if (zipcode >= 84000 && zipcode <= 84999) {
    stateAbbr = 'UT';
    state = 'Utah';
  } else if (zipcode >= 5000 && zipcode <= 5999) {
    stateAbbr = 'VT';
    state = 'Vermont';
  } else if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode == 20598
  ) {
    stateAbbr = 'VA';
    state = 'Virginia';
  } else if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    stateAbbr = 'DC';
    state = 'Washington DC';
  } else if (zipcode >= 98000 && zipcode <= 99499) {
    stateAbbr = 'WA';
    state = 'Washington';
  } else if (zipcode >= 24700 && zipcode <= 26999) {
    stateAbbr = 'WV';
    state = 'West Virginia';
  } else if (zipcode >= 53000 && zipcode <= 54999) {
    stateAbbr = 'WI';
    state = 'Wisconsin';
  } else if (zipcode >= 82000 && zipcode <= 83199) {
    stateAbbr = 'WY';
    state = 'Wyoming';
  } else {
    throw new Error('Invalid zip code provided');
  }

  return { stateAbbr, state };
};

export const validateState = (val: string) =>
  states.includes(val.toUpperCase());

export const wait = (ms) => new Promise((r) => setTimeout(r, ms));

export const formatCurrencyString = (val) => {
  const numeric = removeCommasAfterDecimal(
    numberWithCommas(val.toString().replace(/[^0-9.-]/g, ''))
  );

  const match = /^[-]?([0-9]+([.][0-9]*)?)$/.test(numeric);
  if (match || ['-', ''].includes(numeric)) {
    return numeric;
  } else if (numeric.endsWith('-')) {
    return numeric.slice(0, numeric.length - 1);
  } else if (numeric === '.') {
    return '0.';
  } else if (numeric === '-.') {
    return '-0.';
  } else {
    const first = new Set();
    const replace = (match: string, group: string): string => {
      if (!first.has(group)) {
        first.add(group);
        return match;
      } else {
        return '';
      }
    };
    return numeric.replace(/([-.])/g, replace);
  }
};

export const obfuscateEmail = (email: string) =>
  email.replace('@', '-at-').replace('.', '-dot-');

export const SUPPORT_NUMBER = '(201) 777-1898';
export const SUPPORT_EMAIL = 'support@solve.finance';

export const HIDE_FROM_VIEWER_SOFTWARE = 'hidden-element';

export const stringValueGreaterThanZero = (value: string) => {
  // @ts-ignore
  const withoutCommas = value.replaceAll(',', '');
  return Number(withoutCommas) > 0;
};

export const stringValuePositive = (value: string) => {
  // @ts-ignore
  const withoutCommas = value.replaceAll(',', '');
  return Number(withoutCommas) >= 0;
};

export const isValidMonth = (value: string) => {
  return Number(value) > 0 && Number(value) < 13;
};

export const isValidDay = (value: string) => {
  return Number(value) > 0 && Number(value) < 32;
};

export const isValidYear = (value: string) => {
  return Number(value) > 1900 && Number(value) < 2023;
};

export const lengthLessThanTen = (value: string) => {
  // @ts-ignore
  const withoutCommas = value.replaceAll(',', '');
  return Number(withoutCommas) < 10000000000;
};

export const uuidv4 = () => {
  return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const kFormatter = (val: number): string | number => {
  // Millions
  if (val > 999999) {
    return (val / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
  }
  // Hundred thousands
  if (val > 99999) {
    return (val / 1000).toFixed(0).replace(/\.0$/, '') + 'k';
  }
  // Ten thousands
  if (val > 9999) {
    return (val / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  } else {
    return val.toFixed(0);
  }
};

export const formatDate = (
  dob: string,
  month: string,
  day: string,
  year: string
) => {
  let dateOfBirth;

  if (dob) {
    // for desktop
    dateOfBirth = formatDobFromDate(dob);
  } else {
    // for mobile
    dateOfBirth = formatDobString({ year, month, day });
  }

  return dateOfBirth;
};

export const currencyToNumber = (value?: string) => {
  if (typeof value !== 'string') return null;

  // @ts-ignore
  const withoutCommas = value.replaceAll(',', '');

  return Number(withoutCommas);
};

export const PLAN_PRICE = 9;
export const YEARLY_PLAN_PRICE = 19;

export const pluralize = (word: string, count?: number) => {
  if (count === 1) return word;

  return word + 's';
};

export const scrollToTop = () => {
  if (window) window.scrollY = 0;
  document.scrollingElement.scrollTop = 0;

  const root = document.getElementById('root');
  if (root) root.scrollTop = 0;
};

export const IMAGES = {
  GRAY_SQUARES: {
    ref: 'GRAY_SQUARES',
    NATIVE: './images/GraySquares.svg',
    WEB: '7f76f24c-212e-4f2c-6d64-f8e85ac25800',
  },
  SCREENSHOT: {
    ref: 'SCREENSHOT',
    NATIVE: './images/iphone-screen-score.png',
    WEB: 'ab10269f-5b90-4df9-6fef-445efc124c00',
  },
  HOUSE_TAG: {
    ref: 'HOUSE_TAG',
    NATIVE: './images/app-welcome/HouseTag.jpg',
    WEB: 'a9ed6d0e-77ab-4a90-8988-f23cc5076400',
  },
  PERSON_1: {
    ref: 'PERSON_1',
    NATIVE: './images/Person1.png',
    WEB: 'd74770a8-ba77-4e48-cd69-b1b122ce6c00',
  },
  BUY_A_HOME: {
    ref: 'BUY_A_HOME',
    NATIVE: './images/BuyAHome.png',
    WEB: 'f3329d62-8a96-43b0-430c-41bdff180d00',
  },
  BUY_A_HOME_ACTIVE: {
    ref: 'BUY_A_HOME_ACTIVE',
    NATIVE: './images/BuyAHome-Active.png',
    WEB: '954c8616-8288-47b0-58c7-c555d9459800',
  },
  SOLVE_LOGO_BLACK_TEXT: {
    ref: 'SOLVE_LOGO_BLACK_TEXT',
    NATIVE: './images/solveLogoBlackText.svg',
    WEB: 'e178ac94-60df-4aed-083f-c369c3b09400',
  },
  SCREENSHOT_SVG: {
    ref: 'SCREENSHOT_SVG',
    NATIVE: './images/iphone-screen-score.png',
    WEB: 'ab10269f-5b90-4df9-6fef-445efc124c00',
  },
  SQUARE_1: {
    ref: 'SQUARE_1',
    NATIVE: './images/Square1.svg',
    WEB: 'f18a1f46-2a86-4d17-d235-5572b0c9d700',
  },
  SQUARE_2: {
    ref: 'SQUARE_2',
    NATIVE: './images/Square2.svg',
    WEB: '36ecabac-03c3-469d-6d77-5b978ad99c00',
  },
  SQUARE_3: {
    ref: 'SQUARE_3',
    NATIVE: './images/Square3.svg',
    WEB: '99a091ff-21f8-420c-4da9-c4098b823000',
  },
  SQUARE_4: {
    ref: 'SQUARE_4',
    NATIVE: './images/Square4.svg',
    WEB: 'f893e482-87bd-41fc-1152-92619773a000',
  },
  LAPTOP_BLUE_MUG: {
    ref: 'LAPTOP_BLUE_MUG',
    NATIVE: './images/LaptopBlueMug.svg',
    WEB: 'fc72b187-cb5c-4465-bced-cd42f62b9000',
  },
  BALLOON_DOG: {
    ref: 'BALLOON_DOG',
    NATIVE: './images/BalloonDog.svg',
    WEB: 'cab645c5-6db9-4660-252a-753e7f8fea00',
  },
  COFFEE_AND_PENCIL: {
    ref: 'COFFEE_AND_PENCIL',
    NATIVE: './images/CoffeeAndPencil.svg',
    WEB: 'c77d53eb-b680-40f8-115a-e5e77efd4500',
  },
  BETTER: {
    ref: 'BETTER',
    NATIVE: './images/better.svg',
    WEB: 'dc41663f-d6be-4e7f-4165-5c278a02fd00',
  },
  QUONTIC: {
    ref: 'QUONTIC',
    NATIVE: './images/quontic.svg',
    WEB: 'a86217af-4af9-42d4-bd18-5f3af84b7e00',
  },
  MARBLE: {
    ref: 'MARBLE',
    NATIVE: './images/marble.svg',
    WEB: 'b6a79dbf-87b4-47bd-2817-97d76b2fa400',
  },
  RENOFI: {
    ref: 'RENOFI',
    NATIVE: './images/renofi.svg',
    WEB: 'cc124edc-1378-40f1-a93c-a0292a932100',
  },
  SHAPES_ON_STEPS: {
    ref: 'SHAPES_ON_STEPS',
    NATIVE: './images/ShapesOnSteps.svg',
    WEB: 'a5f4b900-1435-45f2-f357-53d82698d900',
  },
  NO_SPAM: {
    ref: 'NO_SPAM',
    NATIVE: './images/Nospam.svg',
    WEB: '61a0ea29-64fa-4889-b4dd-4dc67b6fe900',
  },
  PERSONALIZED: {
    ref: 'PERSONALIZED',
    NATIVE: './images/Personalized.svg',
    WEB: '91ac2598-17e8-44f9-9977-ba3e7cecda00',
  },
  SAVE: {
    ref: 'SAVE',
    NATIVE: './images/Save.svg',
    WEB: '8e917b83-8bb6-4c9c-34da-d979ba73db00',
  },
  MAGNIFY: {
    ref: 'MAGNIFY',
    NATIVE: './images/Magnify.svg',
    WEB: 'f40ed6a6-9a05-4c9d-5429-4fa72c2ef900',
  },
  CHEVRON_DOWN: {
    ref: 'CHEVRON_DOWN',
    NATIVE: './images/ChevronDown.svg',
    WEB: '29d3832f-fc8d-4674-011c-ac6111750800',
  },
  GREY_WINDOW: {
    ref: 'GREY_WINDOW',
    NATIVE: './images/GreyWindow.svg',
    WEB: '2122c7cf-949f-4869-ef61-4736db892000',
  },
  BLUE_WINDOW: {
    ref: 'BLUE_WINDOW',
    NATIVE: './images/BlueWindow.svg',
    WEB: '9aec7e86-62f1-4444-3608-d691c9569000',
  },
  YELLOW_WINDOW: {
    ref: 'YELLOW_WINDOW',
    NATIVE: './images/YellowWindow.svg',
    WEB: 'dc302b37-76a2-47c2-b800-837750604600',
  },
  POINTER: {
    ref: 'POINTER',
    NATIVE: './images/pointer.svg',
    WEB: 'd4ffcaca-6b4a-438e-a629-44f58cd5ff00',
  },
  CREDIT_CARD: {
    ref: 'CREDIT_CARD',
    NATIVE: './images/CreditCard.svg',
    WEB: '098a5ac0-c7a7-4085-0696-334ec6f1ee00',
  },
  CUBE: {
    ref: 'CUBE',
    NATIVE: './images/Cube.svg',
    WEB: '432afc41-eed8-43de-0342-dd3aeef17c00',
  },
  KEY: {
    ref: 'KEY',
    NATIVE: './images/Key.svg',
    WEB: 'b17b15fa-b184-441f-0fc6-69819b699b00',
  },
  RENT_HOME: {
    ref: 'RENT_HOME',
    NATIVE: './images/RentHome.svg',
    WEB: 'bbb040c7-8ee6-4a65-ef3b-ce065c95c900',
  },
  OWN_HOME: {
    ref: 'OWN_HOME',
    NATIVE: './images/OwnHome.svg',
    WEB: 'b6158a60-f593-4d94-8b79-177dac1fde00',
  },
  BLUE_STAIRS: {
    ref: 'BLUE_STAIRS',
    NATIVE: './images/BlueStairs.svg',
    WEB: '8d9574a4-2ff1-4564-c0e6-178810cf9100',
  },
  SAVINGS_ICON: {
    ref: 'SAVINGS_ICON',
    NATIVE: './images/SavingsIcon.svg',
    WEB: 'a0a47b56-0a0c-413d-b80b-7ee8d3595c00',
  },
  BLUE_MONEY: {
    ref: 'BLUE_MONEY',
    NATIVE: './images/BlueMoney.svg',
    WEB: '138a39a4-1dfc-42ab-2486-5217ac7cb900',
  },
  QUOTES: {
    ref: 'QUOTES',
    NATIVE: './images/Quotes.svg',
    WEB: '205c284e-e689-4b06-ec87-342b2f2a0900',
  },
  GRID: {
    ref: 'GRID',
    NATIVE: './images/Grid.svg',
    WEB: 'f5304bd7-ee91-48a2-1fad-017dae19b800',
  },
  GOOGLE_ICON: {
    ref: 'GOOGLE_ICON',
    NATIVE: './images/GoogleIcon.svg',
    WEB: '72aa3568-9375-425d-a1df-c71d4bc6a900',
  },
  APPLE_BUTTON: {
    ref: 'APPLE_BUTTON',
    NATIVE: './images/apple_icon.png',
    WEB: 'aebaa48a-a7b8-462b-35cf-c05608c88f00',
  },
  APP_STORE_ICON: {
    ref: 'APP_STORE_ICON',
    NATIVE: './images/graphic-icon-app-store.webp',
    WEB: 'd6dfe377-1ed2-4b6c-a8d3-2ffb744fb700',
  },
  PLAY_STORE_ICON: {
    ref: 'PLAY_STORE_ICON',
    NATIVE: './images/graphic-icon-play-store.webp',
    WEB: '5aa65db5-070c-4e37-dfc0-d6d603107900',
  },
  GOAL_CASH: {
    ref: 'GOAL_CASH',
    NATIVE: './images/goal_cash.png',
    WEB: '9fdb6399-1df3-4236-4e9a-fbaa63cfdc00',
  },
  GOAL_CASH_BLUE: {
    ref: 'GOAL_CASH_BLUE',
    NATIVE: './images/goal_cash_blue.png',
    WEB: '35ad58e7-6c02-43f2-efa4-6c2458936f00',
  },
  GOAL_TOTAL: {
    ref: 'GOAL_TOTAL',
    NATIVE: './images/goal_total.png',
    WEB: '0f850416-569c-4b9e-dc3d-0289b30caf00',
  },
  GOAL_TOTAL_BLUE: {
    ref: 'GOAL_TOTAL_BLUE',
    NATIVE: './images/goal_total_blue.png',
    WEB: 'cc98a3c0-9d8c-4a0b-3c46-40d0dabd2200',
  },
  GOAL_MONTHLY: {
    ref: 'GOAL_MONTHLY',
    NATIVE: './images/goal_monthly.png',
    WEB: '75389af9-b769-48f5-3b25-741fa951f400',
  },
  GOAL_MONTHLY_BLUE: {
    ref: 'GOAL_MONTHLY_BLUE',
    NATIVE: './images/goal_monthly_blue.png',
    WEB: '4193dc00-af22-4488-6fdb-65788dec6c00',
  },
  GOAL_HOME: {
    ref: 'GOAL_HOME',
    NATIVE: './images/goal_home.png',
    WEB: 'dd8ee82d-87bc-4e93-d8cb-cac6ce65bc00',
  },
  GOAL_HOME_BLUE: {
    ref: 'GOAL_HOME_BLUE',
    NATIVE: './images/goal_home_blue.png',
    WEB: 'ab5a9793-af77-4b05-debe-cfb522982800',
  },
  LOGO_AUTOAPPROVE: {
    ref: 'LOGO_AUTOAPPROVE',
    NATIVE: './images/logo_autoapprove.svg',
    WEB: '7184c32b-f68c-48a2-3f0a-26719858d300',
  },
  LOGO_ILENDING: {
    ref: 'LOGO_ILENDING',
    NATIVE: './images/logo_ilending.svg',
    WEB: '2541ed7d-2d19-4741-4523-8769368ce500',
  },
  LOGO_BANKRATE: {
    ref: 'LOGO_BANKRATE',
    NATIVE: './images/logos/logo_bankrate.jpeg',
    WEB: 'b65d2999-ec35-4ecb-d0a9-9c3eccd64d00',
  },
  LOGO_BESTEGG: {
    ref: 'LOGO_BESTEGG',
    NATIVE: './images/logos/logo_bestegg.png',
    WEB: 'e2b16047-48da-4099-a160-79c9edb25900',
  },
  LOGO_BETTER: {
    ref: 'LOGO_BETTER',
    NATIVE: './images/logos/logo_better.png',
    WEB: '059db00e-76c2-4c37-bf54-6ae4b9a9da00',
  },
  LOGO_CARIBOU: {
    ref: 'LOGO_CARIBOU',
    NATIVE: './images/logos/logo_caribou.jpeg',
    WEB: '01a2374b-be53-485e-f4c5-f2aa5aee5100',
  },
  LOGO_JGWENTWORTH: {
    ref: 'LOGO_JGWENTWORTH',
    NATIVE: './images/logos/logo_jg_wentworth.png',
    WEB: '4221d541-5503-44d6-4891-61d763160400',
  },
  LOGO_LENDKEY: {
    ref: 'LOGO_LENDKEY',
    NATIVE: './images/logos/logo_lendkey.png',
    WEB: '39b68dc6-d3cb-4ce3-1040-94303b459e00',
  },
  LOGO_MORTECH: {
    ref: 'LOGO_MORTECH',
    NATIVE: './images/logos/logo_mortech.png',
    WEB: '7620e333-51aa-48be-2859-f66bad3e7700',
  },
  LOGO_NERDWALLET: {
    ref: 'LOGO_NERDWALLET',
    NATIVE: './images/logos/logo_nerdwallet.png',
    WEB: '269c1e31-5f93-4369-4c0e-6aec7fcba000',
  },
  LOGO_NETCASHMAN: {
    ref: 'LOGO_NETCASHMAN',
    NATIVE: './images/logos/logo_netcashman.jpg',
    WEB: '1c659474-483b-4019-aefa-9707a4884900',
  },
  LOGO_SOFI: {
    ref: 'LOGO_SOFI',
    NATIVE: './images/logos/logo_sofi.png',
    WEB: 'c7de6278-a11e-413f-fc00-9e86065fc100',
  },
  LOGO_SPOTLIGHT: {
    ref: 'LOGO_SPOTLIGHT',
    NATIVE: './images/logos/logo_spotlight.png',
    WEB: 'd4e8bff4-f434-430e-8971-763a1cacf500',
  },
  LOGO_UNLOCK: {
    ref: 'LOGO_UNLOCK',
    NATIVE: './images/logos/logo_unlock.png',
    WEB: '29de1f59-6865-4675-1d6a-421388443f00',
  },
  LOGO_UPSTART: {
    ref: 'LOGO_UPSTART',
    NATIVE: './images/logos/logo_upstart.png',
    WEB: '1eb69500-45e9-4376-70e6-6f38f709cc00',
  },
  LOGO_SAVI: {
    ref: 'LOGO_SAVI',
    NATIVE: './images/logos/logo_savi.svg',
    WEB: '74f10421-9613-4ffe-5760-fd9748b1e200',
  },
  LOGO_GROWCREDIT: {
    ref: 'LOGO_GROWCREDIT',
    NATIVE: './images/logos/logo_growcredit.png',
    WEB: '84512e03-d87e-4c25-200f-24cb773b2d00',
  },
  LOGO_BOOMPAY: {
    ref: 'LOGO_BOOMPAY',
    NATIVE: './images/logos/logo_boompay.webp',
    WEB: 'f74b75b1-f10e-440d-47cd-f54e9187bd00',
  },
  LOGO_CREDITBUILDERCARD: {
    ref: 'LOGO_CREDITBUILDERCARD',
    NATIVE: './images/logos/logo_creditbuildercard.png',
    WEB: 'd5d6877b-3053-4a1d-9ae4-353959b74300',
  },
  LOGO_DOLVY: {
    ref: 'LOGO_DOLVY',
    NATIVE: './images/logos/logo_dolvy.webp',
    WEB: 'a419fd58-120a-48c6-9071-6f6b0249a400',
  },
  LOGO_KIKOFF: {
    ref: 'LOGO_KIKOFF',
    NATIVE: './images/logos/logo_kikoff.svg',
    WEB: 'bf193984-a9d5-44ec-6fea-90cceebeb000',
  },
  LOGO_KONDUIT: {
    ref: 'LOGO_KONDUIT',
    NATIVE: './images/logos/logo_konduit.png',
    WEB: '90b5289a-1e36-4f6d-8733-8311771b4100',
  },
  AVATAR_1: {
    ref: 'AVATAR_1',
    NATIVE: './images/avatars/avatar-1.png',
    WEB: 'aaf924a3-4ae5-4ddd-158a-3373a64ea500',
  },
  AVATAR_2: {
    ref: 'AVATAR_2',
    NATIVE: './images/avatars/avatar-2.png',
    WEB: '385512ae-ab94-4088-f152-6b45f0076500',
  },
  AVATAR_3: {
    ref: 'AVATAR_3',
    NATIVE: './images/avatars/avatar-3.png',
    WEB: 'b3bfa683-5203-4cd7-d210-cab33cfd5c00',
  },
  AVATAR_4: {
    ref: 'AVATAR_4',
    NATIVE: './images/avatars/avatar-4.png',
    WEB: '1d0a6282-dcb7-4f37-5226-7adf7a4bd800',
  },
  AVATAR_5: {
    ref: 'AVATAR_5',
    NATIVE: './images/avatars/avatar-5.png',
    WEB: '414e4f92-a933-4853-69b7-cb6b72c05500',
  },
  AVATAR_6: {
    ref: 'AVATAR_6',
    NATIVE: './images/avatars/avatar-6.png',
    WEB: '599279df-7a03-41c8-f23e-ee3f358f7000',
  },
};

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/debt-optimizer/id6478185942?itsct=apps_box_link&itscg=30200';
export const PLAY_STORE_URL =
  'http://play.google.com/store/apps/details?id=finance.solve.app';

export const IMAGE_CDN_URL =
  'https://imagedelivery.net/ajFIjriPM7Nq8IlR5O58Qw/:imageId/public';

export const getImage = (ref: string) => {
  // if (!IMAGES[ref]) return '';
  // if (Capacitor.isNativePlatform()) return require(IMAGES[ref]?.NATIVE);

  // console.log("ref", ref, IMAGES[ref]);
  return IMAGE_CDN_URL.replace(':imageId', IMAGES[ref]?.WEB);
};

export const handleTap = (cb: (e) => void) => {
  return (e) => {
    e.preventDefault();
    cb(e);
  };
};

export function getQueryParams() {
  const params = {};
  const queryString = window.location.search.substring(1);
  const regex = /([^&=]+)=([^&]*)/g;
  let match;
  while ((match = regex.exec(queryString))) {
    params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
  }
  return params;
}

const CONVERSION_VALUES = {
  MORTGAGE: 25,
  SECOND_MORTGAGE: 20,
  HEI: 15,
  PERSONAL: 0.5,
  AUTO: 2.5,
  STUDENT: 1,
  SAVI: 0,
  GROWCREDIT: 1,
  BOOMPAY: 2,
  DOVLY: 0.5,
  SOLVE: PLAN_PRICE,
  SOLVE_LOAN: 10,
  CREDITBUILDERCARD: 1,
  KIKOFF: 1,
};

export const LOW_INCOME_CONFIRMATION = 20000;
export const HIGH_INCOME_CONFIRMATION = 40000;

const LOW_SCORE_THRESHOLD = 580;
const SCORE_CONVERSION_THRESHOLD = 680;
const MORTGAGE_CONVERSION_THRESHOLD = 100000;
const SECOND_MORTGAGE_CONVERSION_THRESHOLD = 50000;

export const getCreditPulledValue = (user: IUser) => {
  var isPremium = user.subscriptionType === 'PREMIUM';
  var score = user.currentCreditInfo?.scoreNow || 0;
  var hasLoanInterest = user.loanInterestStatus;
  var isPreQualified = user.application?.isPrequalified;
  var goal = user.goal;

  var value = 0;

  if (score >= LOW_SCORE_THRESHOLD) {
    value += 1;

    if (hasLoanInterest && isPreQualified && goal == DEBT_GOAL.GET_CASH) {
      value += 1.5;
    }
  }

  if (isPremium) {
    value += PLAN_PRICE;
  }

  return value;
};

export const getConversionValue = (
  group: string,
  user?: any,
  offer?: any,
  source?: string
) => {
  var value = 0;

  const formattedGroup = group.toUpperCase();
  if (formattedGroup in CONVERSION_VALUES) {
    value = CONVERSION_VALUES[formattedGroup];
  }

  if (source && source.toUpperCase() in CONVERSION_VALUES) {
    value = CONVERSION_VALUES[source.toUpperCase()];
  }

  if (user && user?.currentCreditInfo?.scoreNow < SCORE_CONVERSION_THRESHOLD) {
    value /= 2;
  }

  if (!['MORTGAGE', 'SECOND_MORTGAGE', 'HEI'].includes(group)) return value;

  var amount =
    offer?.amount?.value == null
      ? offer?.amountPresented
      : offer?.amount?.value;

  if (
    amount != null &&
    ((LOAN_GROUP.MORTGAGE == (offer?.group as LOAN_GROUP) &&
      amount < MORTGAGE_CONVERSION_THRESHOLD) ||
      (LOAN_GROUP.SECOND_MORTGAGE == (offer?.group as LOAN_GROUP) &&
        amount < SECOND_MORTGAGE_CONVERSION_THRESHOLD))
  ) {
    value /= 3;
  }

  return value;
};

export const buildFlexOfferTracking = (
  url: string,
  offerRef: string,
  firebaseUser?: User
): string => {
  if (url.includes('?')) {
    url += '&';
  } else {
    url += '?';
  }

  url += `fobs=${firebaseUser?.uid}&fobs2=${offerRef}`;

  return url;
};

export const getCampaignFromParams = (params: URLSearchParams): string => {
  const utmContent = params.get('utm_content');
  const utmCampaign = params.get('utm_campaign');

  switch (utmContent) {
    case '141553451459':
    case 'save':
      return 'save';
    case '152360798065':
    case 'consolidation':
      return 'consolidation';
    case '138609779462':
    case '171380616145':
    case 'credit-score':
      return 'credit-score';
    default:
      return utmCampaign || utmContent;
  }
};

export const LOAN_APPLICATION_POLICIES = {
  FCMC_TERMS:
    'https://storage.googleapis.com/solve-mdop-documents/policies/FCMC%20-%20Terms%20of%20Use%20-%2020240210.pdf',
  PRIVACY:
    'https://storage.googleapis.com/solve-mdop-documents/policies/Solve%20Finance%20-%20Privacy%20Policy%20-%2020240210.pdf',
  ACH_TERMS:
    'https://storage.googleapis.com/solve-mdop-documents/policies/Solve%20Finance%20-%20ACH%20Authorization%20-%2020240210.pdf',
};

export const STATES_ALLOWED = ['AZ', 'UT', 'TX', 'MO', 'SD', 'SC'];
