import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from '../styles/GlobalStyle';
import { useWindowWidth } from '../../hooks/useWindowSize';

import { ButtonLink, Column, Row } from './components';
import { handleTap } from '../../utils';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0 32px 0;
  & p {
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    max-width: 818px;
  }
  & svg,
  img {
    max-width: 205px;
  }
`;

const Heading = styled('h5')<{ color?: string; size?: 's' | 'l' }>`
  color: ${({ color }) => color || '#101010'};
  margin-bottom: 0;
  margin-top: 32px;
  max-width: 746px;
  font-weight: 400;
  font-size: ${({ size }) => (size === 's' ? '32px' : '38px')};
  @media ${theme.mq.desktopLargeMin} {
    font-size: ${({ size }) => (size === 's' ? '40px' : '47px')};
    line-height: 115%;
  }
`;

export const IconFocusCTA = ({
  icon,
  title,
  paragraph,
  ctas,
}: {
  icon?: React.ReactNode;
  title: { color?: string; text: string; size: 's' | 'l' };
  paragraph?: string | React.ReactNode;
  ctas: {
    bgColor?: string;
    color?: string;
    text: string;
    to?: string;
    callback?: () => void;
  }[];
}) => {
  const { isMobile } = useWindowWidth();
  return (
    <Container>
      {icon}
      {title?.text && (
        <Heading color={title?.color} size={title.size}>
          {title.text}
        </Heading>
      )}
      {paragraph && (
        <p style={{ marginBottom: 0, fontSize: '21px' }}>{paragraph}</p>
      )}
      {isMobile ? (
        <Column style={{ gap: 24, marginTop: 32 }}>
          {ctas.map(({ bgColor, callback, to, color, text }) => (
            <ButtonLink
              key={text}
              to={to}
              as={Link}
              onClick={callback}
              bgColor={bgColor}
              color={color}
            >
              {text}
            </ButtonLink>
          ))}
        </Column>
      ) : (
        <Row style={{ marginTop: 32, gap: 18 }}>
          {ctas.map(({ bgColor, callback, to, color, text }) => (
            <ButtonLink
              key={text}
              to={to}
              onClick={callback}
              as={Link}
              bgColor={bgColor}
              color={color}
              style={{
                minWidth: '277px',
                margin: 'auto',
                height: '36px',
                fontSize: '20px',
              }}
            >
              {text}
            </ButtonLink>
          ))}
        </Row>
      )}
    </Container>
  );
};
