import React, { useContext, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  FirebaseAuthentication,
  LinkWithPhoneNumberOptions,
  User,
} from '@capacitor-firebase/authentication';

import {
  signOut,
  RecaptchaVerifier,
  signInWithCredential,
  EmailAuthProvider,
  linkWithCredential,
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';

import { captureException } from '../analytics/sentry';
import {
  processEventQueue,
  recordEvent,
  removeUser,
  updateIdentity,
} from '../services/user.service';
import {
  getFirebaseAuth,
  initializeFirebase,
} from '../services/firebase.service';
import { getViewportType } from '../hooks/useWindowSize';

export const AUTH_ERROR_CODES = {
  GOOGLE_FAIL_EMAIL_PASSWORD: 'GOOGLE_FAIL_EMAIL_PASSWORD',
  EMAIL_PASSWORD_FAIL_GOOGLE: 'EMAIL_PASSWORD_FAIL_GOOGLE',
  PHONE_VERIFICATION_FAILED: 'PHONE_VERIFICATION_FAILED',
  UNHANDLED: 'UNHANDLED',
};

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [requestOTP, setRequestOTP] = useState(false);
  const [hasValidCaptcha, setHasValidCaptcha] = useState(false);
  const [invisibleCaptcha, setInvisibleCaptcha] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        await initializeFirebase({
          setFirebaseUser,
          setLoading,
          setError,
          processEventQueue,
          setRequestOTP,
          setInitialized,
          setInvisibleCaptcha,
          destroyRecaptcha,
        });

        await handleAuthRedirect();
      } catch (e) {
        console.log('AuthProvider - Error', e, 'Initializing');
        captureException(e);

        setLoading(false);
        setInitialized(true);
      }

      console.debug('AuthProvider - Initialized');
    };

    initialize();
  }, []);

  const destroyRecaptcha = () => {
    window.recaptchaVerifier?.clear();
    window.recaptchaVerifier = null;
  };

  const initializeRecaptcha = (domId: string) => {
    if (Capacitor.isNativePlatform()) {
      setHasValidCaptcha(true);
      return;
    }

    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaDomId = domId;
      window.recaptchaVerifier = new RecaptchaVerifier(
        getFirebaseAuth(),
        domId,
        {
          size: invisibleCaptcha ? 'invisible' : 'normal',
          callback: () => {
            setHasValidCaptcha(true);
          },
          'expired-callback': (err) => {
            console.error('reCaptcha expired', err);
            setInvisibleCaptcha(false);
            setHasValidCaptcha(false);
            initializeRecaptcha(domId);
          },
          'error-callback': (err) => {
            console.error('reCaptcha error-callback', err);
            setInvisibleCaptcha(false);
            setHasValidCaptcha(false);
            initializeRecaptcha(domId);
          },
        }
      );

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } else if (window.recaptchaWidgetId && window.grecaptcha) {
      window.grecaptcha.reset(window.recaptchaWidgetId);
    }
  };

  const handleSignUp = async () => {
    if (!firebaseUser) {
      setLoading(true);

      recordEvent({ eventName: 'Sign Up Started', firebaseUser });
      await createAnonAccount();

      setLoading(false);
    }
  };

  const handleAuthRedirect = async () => {
    if (Capacitor.isNativePlatform()) {
      setInitialized(true);

      return;
    }

    try {
      const loadingTimeout = setTimeout(() => {
        setLoading(true);
      }, 500);

      const result = await FirebaseAuthentication.getRedirectResult();

      if (result) {
        if (result.user) {
          setFirebaseUser(result.user);
          setInitialized(true);

          await recordEvent({
            eventName: 'Sign In',
            firebaseUser: result.user,
            props: { type: 'SOCIAL' },
          });

          window.onbeforeunload = null;
        }
      }

      clearTimeout(loadingTimeout);
      setLoading(false);
    } catch (err) {
      const providerId = err.customData?._tokenResponse?.providerId;

      if (!providerId) {
        captureException(err);
        setError(AUTH_ERROR_CODES.UNHANDLED);
      } else {
        switch (err.code) {
          case 'auth/email-already-in-use':
          case 'auth/account-exists-with-different-credential':
            setError(AUTH_ERROR_CODES.GOOGLE_FAIL_EMAIL_PASSWORD);
            break;
          case 'auth/credential-already-in-use':
            try {
              let credential;
              let provider;

              switch (providerId) {
                case GoogleAuthProvider.PROVIDER_ID:
                  credential = GoogleAuthProvider.credentialFromError(err);
                  provider = 'Google';
                  break;
              }

              await signInWithCredential(getFirebaseAuth(), credential);
              const currUser = await FirebaseAuthentication.getCurrentUser();

              setFirebaseUser(currUser.user);
            } catch (e) {
              captureException(e);
              setError(AUTH_ERROR_CODES.UNHANDLED);
            }
            break;
          default:
            captureException(err);
            setError(AUTH_ERROR_CODES.UNHANDLED);
        }
      }

      setInitialized(true);
      setLoading(false);
    }
  };

  const createAnonAccount = async () => {
    setLoading(true);

    try {
      const result = await FirebaseAuthentication.signInAnonymously();

      updateIdentity({
        platform: Capacitor.getPlatform(),
        viewport: getViewportType(),
      });
      setFirebaseUser(result.user);
      setLoading(false);
    } catch (err) {
      captureException(err);
      setLoading(false);

      throw err;
    }
  };

  const handleDeleteUser = async () => {
    try {
      if (firebaseUser) {
        await removeUser(
          firebaseUser.uid,
          (
            await FirebaseAuthentication.getIdToken()
          ).token
        );
      }
    } catch (err) {
      captureException(err);

      throw err;
    }
  };

  const handleSignOut = async () => {
    setLoading(true);
    setRequestOTP(false);

    try {
      await FirebaseAuthentication.signOut();

      await signOut(getFirebaseAuth());
      setLoading(false);
    } catch (err) {
      captureException(err);
      setLoading(false);

      throw err;
    }
  };

  const handleRequestPasswordReset = async (email: string) => {
    await FirebaseAuthentication.sendPasswordResetEmail({ email });
  };

  const handleConfirmPasswordReset = async (
    oobCode: string,
    newPassword: string
  ) => {
    await FirebaseAuthentication.confirmPasswordReset({ oobCode, newPassword });
  };

  const handleSignIn = async (
    email: string,
    password: string
  ): Promise<void> => {
    setLoading(true);

    try {
      const user = await FirebaseAuthentication.getCurrentUser();

      recordEvent({
        eventName: 'Sign In',
        firebaseUser: user.user,
        props: { type: 'EMAIL' },
      });

      await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
    } catch (err) {
      setLoading(false);

      throw err;
    }
  };

  const handleApplyActionCode = async (oobCode: string) =>
    FirebaseAuthentication.applyActionCode({ oobCode });

  const handelCheckAuthMethod = async (method: string, account: string) => {
    try {
      const response = await fetch(
        `${process.env.API_URL}/v1/auth/check-account/${method}/${account}`,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        }
      );

      switch (response.status) {
        case 200:
          const result = await response.json();
          return result;
        default:
          const errorData = await response.json();
          throw new Error(errorData.message);
      }
    } catch (err) {
      console.log(`check account error: ${err.message}`);
      throw new Error(err);
    }
  };

  const linkAppleAuth = async () => {
    if (firebaseUser) {
      setLoading(true);

      if (Capacitor.isNativePlatform()) {
        try {
          const result = await FirebaseAuthentication.linkWithApple();

          setFirebaseUser(result.user);
          setLoading(false);
        } catch (err) {
          setLoading(false);

          throw err;
        }
      } else {
        window.onbeforeunload = null;
        await FirebaseAuthentication.linkWithApple({ mode: 'redirect' });
      }
    }
  };

  const linkGoogleAuth = async () => {
    if (firebaseUser) {
      setLoading(true);

      if (Capacitor.isNativePlatform()) {
        try {
          const result = await FirebaseAuthentication.linkWithGoogle();

          setFirebaseUser(result.user);
          setLoading(false);
        } catch (err) {
          setLoading(false);

          throw err;
        }
      } else {
        window.onbeforeunload = null;
        await FirebaseAuthentication.linkWithGoogle({ mode: 'redirect' });
      }
    }
  };

  const linkEmailPassword = async (
    email: string,
    password: string
  ): Promise<void> => {
    setLoading(true);

    if (firebaseUser) {
      try {
        let isSignUp = firebaseUser?.isAnonymous;
        let result;

        if (Capacitor.isNativePlatform()) {
          result = await FirebaseAuthentication.linkWithEmailAndPassword({
            email,
            password,
          });
        } else {
          const credential = EmailAuthProvider.credential(email, password);

          if (isSignUp) {
            await linkWithCredential(getFirebaseAuth().currentUser, credential);
          }

          await signInWithCredential(getFirebaseAuth(), credential);
          result = await FirebaseAuthentication.getCurrentUser();
        }

        if (isSignUp) {
          recordEvent({
            eventName: 'Sign Up',
            firebaseUser: result.user,
            props: { type: 'EMAIL' },
          });
        } else {
          recordEvent({
            eventName: 'Sign In',
            firebaseUser: result.user,
            props: { type: 'EMAIL' },
          });
        }

        console.log('not loading...', result.user);
        setFirebaseUser(result.user);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);

        throw err;
      }
    }
  };

  const handleGoogleAuthSignIn = async () => {
    setLoading(true);

    if (Capacitor.isNativePlatform()) {
      try {
        const result = await FirebaseAuthentication.signInWithGoogle();

        setFirebaseUser(result.user);
      } catch (err) {
        setLoading(false);

        throw err;
      }
    } else {
      window.onbeforeunload = null;
      await FirebaseAuthentication.signInWithGoogle({ mode: 'redirect' });
    }
  };

  const handleAppleAuthSignIn = async () => {
    setLoading(true);
    if (Capacitor.isNativePlatform()) {
      try {
        const result = await FirebaseAuthentication.signInWithApple();
        setFirebaseUser(result.user);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    } else {
      window.onbeforeunload = null;
      await FirebaseAuthentication.signInWithApple({ mode: 'redirect' });
    }
  };

  const handleVerifyEmail = async () => {
    await FirebaseAuthentication.sendEmailVerification();
  };

  const handleVerifyPhoneNumber = async (
    phoneNumber: string,
    resendCode: boolean,
    isSignUp: boolean
  ) => {
    try {
      let formattedPhone = phoneNumber.replace(/[^0-9]/g, '');
      formattedPhone =
        formattedPhone.length === 10
          ? `+1${formattedPhone}`
          : `+${formattedPhone}`;

      const options: LinkWithPhoneNumberOptions = {
        phoneNumber: formattedPhone,
        resendCode,
      };

      if (!Capacitor.isNativePlatform()) {
        initializeRecaptcha(
          isSignUp ? 'recaptcha-sign-up' : 'recaptcha-sign-in'
        );
        options.recaptchaVerifier = window.recaptchaVerifier;
      }

      if (isSignUp) {
        await FirebaseAuthentication.linkWithPhoneNumber(options);
      } else {
        await FirebaseAuthentication.signInWithPhoneNumber(options);
      }
    } catch (err) {
      console.error(err);
      captureException(err);

      setLoading(false);
      setRequestOTP(false);

      if (!Capacitor.isNativePlatform() && window.grecaptcha)
        window.grecaptcha.reset(window.recaptchaWidgetId);
      throw err;
    }
  };

  const handleConfirmPhoneNumber = async (verificationCode: string) => {
    setLoading(true);

    try {
      const result = await FirebaseAuthentication.confirmVerificationCode({
        verificationId: window.verificationId,
        verificationCode,
      });

      setFirebaseUser(result.user);
      setLoading(false);
    } catch (err) {
      console.error(err);
      captureException(err);

      setLoading(false);
      setRequestOTP(false);

      throw err;
    }
  };

  const context = {
    error,
    setError,
    createAnonAccount,
    handleSignUp,
    handleSignOut,
    handleDeleteUser,
    handleRequestPasswordReset,
    handleConfirmPasswordReset,
    handleSignIn,
    handleApplyActionCode,
    handleAuthRedirect,
    handleGoogleAuthSignIn,
    handleAppleAuthSignIn,
    handleVerifyEmail,
    handleVerifyPhoneNumber,
    handleConfirmPhoneNumber,
    handelCheckAuthMethod,
    linkAppleAuth,
    linkGoogleAuth,
    linkEmailPassword,
    firebaseUser,
    loading,
    initialized,
    initializeRecaptcha,
    destroyRecaptcha,
    hasValidCaptcha,
    requestOTP,
    setRequestOTP,
  };

  console.debug('AuthProvider Context:', context);

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

interface IAuthContext {
  createAnonAccount: () => Promise<void>;
  handleDeleteUser: () => Promise<void>;
  handleSignUp: () => Promise<void>;
  handleSignOut: () => Promise<void>;
  handleRequestPasswordReset: (email: string) => Promise<void>;
  handleConfirmPasswordReset: (code: string, password: string) => Promise<void>;
  handleSignIn: (email: string, password: string) => Promise<void>;
  handleApplyActionCode: (oobCode: string) => Promise<void>;
  linkAppleAuth: () => Promise<User | null>;
  linkGoogleAuth: () => Promise<User | null>;
  handleGoogleAuthSignIn: () => Promise<void>;
  handleAppleAuthSignIn: () => Promise<void>;
  linkEmailPassword: (email: string, password: string) => Promise<User | null>;
  handleVerifyEmail: () => Promise<void>;
  handleVerifyPhoneNumber: (
    phoneNumber: string,
    resend: boolean,
    isSignUp?: boolean
  ) => Promise<void>;
  handleConfirmPhoneNumber: (
    verificationCode: string,
    isSignUp?: boolean
  ) => Promise<void>;
  handelCheckAuthMethod: (method: string, account: string) => Promise<any>;
  firebaseUser?: User;
  loading: boolean;
  initialized: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  initializeRecaptcha: (domId: string) => void;
  destroyRecaptcha: () => void;
  hasValidCaptcha: boolean;
  requestOTP: boolean;
  setRequestOTP: (requestOTP: boolean) => void;
}

export const useAuthContext = (): IAuthContext => {
  return useContext(AuthContext);
};
