export const USER_ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_USER_INFO: 'SET_USER_INFO',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  SET_UTM_CONTENT: 'SET_UTM_CONTENT',
  RESET_STATE: 'RESET_STATE',
  IS_INITIALIZED: 'IS_INITIALIZED',
  SET_BUREAU_PROVIDER_REF: 'SET_BUREAU_PROVIDER_REF',
  SET_HAS_BUREAU_AUTH: 'SET_HAS_BUREAU_AUTH',
  SET_HAS_ONBOARDED: 'SET_HAS_ONBOARDED',
  SET_RUN_SCRAPERS: 'SET_RUN_SCRAPERS',
  SET_GOAL: 'SET_GOAL',
};

export const isInitialized = () => ({
  type: USER_ACTIONS.IS_INITIALIZED,
});

export const setLoading = (payload: boolean) => ({
  type: USER_ACTIONS.SET_LOADING,
  payload,
});

export const setUser = (payload: any) => ({
  type: USER_ACTIONS.SET_USER_INFO,
  payload,
});

export const setGoal = (payload: string) => ({
  type: USER_ACTIONS.SET_GOAL,
  payload,
});

export const updateUser = (payload: any) => ({
  type: USER_ACTIONS.UPDATE_USER_INFO,
  payload,
});

export const setBureauProviderRef = (payload: string) => ({
  type: USER_ACTIONS.SET_BUREAU_PROVIDER_REF,
  payload,
});

export const setHasBureauAuth = (payload: boolean) => ({
  type: USER_ACTIONS.SET_HAS_BUREAU_AUTH,
  payload,
});

export const resetState = () => ({
  type: USER_ACTIONS.RESET_STATE,
});
