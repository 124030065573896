import { DEBT_GOAL } from '../../components/Optimizer/types';

export const RECOMMENDATION_ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  IS_INITIALIZED: 'IS_INITIALIZED',
  SET_PLAN: 'SET_PLAN',
  SWITCH_GOAL: 'SWITCH_GOAL',
  INVALIDATE_CACHE: 'INVALIDATE_CACHE',
  RESET_STATE: 'RESET_STATE',
  CREDIT_ATTEMPT: 'CREDIT_ATTEMPT',
  RECOMMENDATION_ATTEMPT: 'RECOMMENDATION_ATTEMPT',
};

export const isInitialized = () => ({
  type: RECOMMENDATION_ACTIONS.IS_INITIALIZED,
});

export const setLoading = (payload: boolean) => ({
  type: RECOMMENDATION_ACTIONS.SET_LOADING,
  payload,
});

export const setPlan = (payload: { plan: IPlan[]; goal: DEBT_GOAL }) => ({
  type: RECOMMENDATION_ACTIONS.SET_PLAN,
  payload,
});

export const switchGoal = (payload: string) => ({
  type: RECOMMENDATION_ACTIONS.SWITCH_GOAL,
  payload,
});

export const invalidateCache = (goal?: string) => ({
  type: RECOMMENDATION_ACTIONS.INVALIDATE_CACHE,
  payment: goal,
});

export const resetState = () => ({
  type: RECOMMENDATION_ACTIONS.RESET_STATE,
});

export const creditAttempt = () => ({
  type: RECOMMENDATION_ACTIONS.CREDIT_ATTEMPT,
});

export const recommendationAttempt = () => ({
  type: RECOMMENDATION_ACTIONS.RECOMMENDATION_ATTEMPT,
});

export interface IPlan {
  recommendationId?: string | null;
  recommendations?: unknown[];
  dtiPotential?: number;
  potentialDebtToIncome?: number;
  debtToIncome?: number;
  timestamp?: string;
  scoreUsed?: null;
  potentialHomeBuyingPower?: any;
  currentHomeBuyingPower?: any;
  potentialCashOut?: any;
  goal?: DEBT_GOAL;
  improvement?: number;
  currentPrincipalBalance?: number;
  hasDtiConstraint?: boolean;
  potentialSavings?: number;
  potentialDebtCost?: number;
  currentDebtCost?: number;
  potentialMonthlyPayments?: number;
  currentMonthlyPayments?: number;
  debtCostDelta?: number;
  monthlyPaymentsDelta?: number;
}

export interface ICachedRecommendationType {
  MIN_MONTHLY?: IPlan | null;
  MinimizeTotalCost?: IPlan | null;
  MinimizeNumberOfPayments?: IPlan | null;
}
