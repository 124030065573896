import { createGlobalStyle } from 'styled-components';

import MajorantMedium from './Majorant-Md.otf';
import MajorantRegular from './Majorant-Rg.otf';
import MajorantThin from './Majorant-Th.woff';
import MajorantLight from './Majorant-Lt.woff';

export const theme = {
  primaryBlueSFColor: '#0286E7',
  white: '#ffffff',
  gray1: '#f9f9f9',
  gray2: '#e1e2e4',
  gray3: '#b1b5b8',
  gray4: '#a1a7ab',
  gray5: '#7e848a',
  gray6: '#636b71',
  gray7: '#02101B',
  gray8: '#000000',
  blue1: '#ddedf9',
  blue2: '#bfe4ff',
  blue3: '#8fd0ff',
  blue4: '#0286E7',
  blue5: '#167dc8',
  blue6: '#0f66ae',
  blue7: '#0B426a',
  green4: '#27AE60',
  red4: '#EB5757',
  mq: {
    phoneMax: '(max-width: 480px)',
    tabletMin: '(min-width: 481px)',
    tabletMax: '(max-width: 768px)',
    tabletLargeMin: '(min-width: 769px)',
    tabletLargeMax: '(max-width: 899px)',
    desktopSmallMin: '(min-width: 900px)',
    desktopMin: '(min-width: 769px)',
    desktopLargeMin: '(min-width: 1080px)',
  },
  navHeightMobile: 60,
  navHeightDesktop: 80,
  navMarginMobile: 26,
  navMarginDesktop: 40,
};

const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

  @font-face {
    font-family: "Majorant-Medium";
    font-display: block;
    src: url(${MajorantMedium}) format("woff2");
  }

  @font-face {
    font-family: "Majorant-Regular";
    font-display: block;
    src: url(${MajorantRegular}) format("woff2");
  }

  @font-face {
    font-family: "Majorant-Thin";
    font-display: block;
    src: url(${MajorantThin}) format("woff2");
  }

  @font-face {
    font-family: "Majorant-Light";
    font-display: block;
    src: url(${MajorantLight}) format("woff2");
  }

  html {
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;
    height: 100%;
    display: flex;
  }

  #root {
    min-height: 100%;
  }

  body {
    flex: 1;
  }

  #root {
    height: auto;
    overflow-x: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  body {
    font-family: "Majorant-Regular";
    overflow-x: hidden;
    margin: 0;
    & .MuiPickersToolbar-toolbar,
     .MuiPickersDay-daySelected:hover,
     .MuiPickersDay-daySelected,
     .MuiButton-textPrimary {
      background-color: ${theme.blue4} !important;
      color: ${theme.white} !important;
    }
    & * {
      -webkit-font-smoothing: antialiased;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }

  .MuiTypography-root {
    font-family: "Majorant-Regular" !important;
  }

  .MuiBottomNavigationAction-root.Mui-selected {
    color: #0369cd !important;
  }

  .MuiTooltip-popper {
    padding: 12px 0;

    & div {
      background: #333 !important;
      padding: 2px 20px !important;
      margin: 0;
    }
  }

  .MuiBottomNavigation-root {
    height: calc(env(safe-area-inset-bottom) + 69px);
  }

  .MuiBottomNavigation-root button.MuiBottomNavigationAction-root {
    padding-bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
    height: calc(env(safe-area-inset-bottom) + 69px) !important;
    background: #fff;
  }

  .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
    background: #fff;
  }

  .intercom-lightweight-app, .intercom-app {
    display: none;
  }

  @media(max-width: 768px) {
    .intercom-lightweight-app-launcher, .intercom-app > div {
      bottom: calc(env(safe-area-inset-bottom) + 112px) !important;
    }
  }

  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    padding-top: env(safe-area-inset-top);
  }
`;

export default GlobalStyle;
